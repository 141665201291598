import Navbar from "../../components/Navbar";
import Footer from '../../components/Footer'
import CertificationSelection from '../../components/CatererSearchOption/CertificationSelection';
import ServingOptionSelection from '../../components/CatererSearchOption/ServingOptionSelection';
import StateSelection from '../../components/CatererSearchOption/StateSelection';
import { useGetDietariesQuery, useSearchCompanyListingQuery } from '../../hooks/apiSlice';
import { useParams, useSearchParams } from "react-router-dom";
import CatererCard from '../../components/CatererCard';
import { useEffect, useState } from "react";
import { default as ReactSelect, createFilter } from "react-select";
import AuthService from "../../hooks/Auth";
import ModalRegister from '../../components/ModalRegister';
import InfiniteScroll from "react-infinite-scroll-component";
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { toPascal, toURLDecode } from '../../hooks/util';
import CompanySelect, { ChildStateModificationFunc } from '../../components/CatererSearchOption/CompanySelect';
import { Helmet } from 'react-helmet-async';
import { getCurrentPathWithoutQueryParameters } from '../../hooks/util';
import EventTypeSelection from './EventTypeSelection';
import SpecialitySelection from './SpecialitySelection';
import AddonServiceSelection from './AddonServiceSelection';
import CuisineSelection from './CuisineSelection';
import CatererCardGrid from '../../components/CatererCardGrid';
import AdShowcase from './../../components/AdShowcase';

const CatererSearch = () => {

  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [skipToken, setSkipToken] = useState(true)
  const [selectedSpeciality, setSelectedSpeciality] = useState(['0']);
  const [selectedServingOpt, setSelectedServingOpt] = useState(['0']);
  const [selectedDietary, setSelectedDietary] = useState(['0']);
  const [selectedCuisine, setSelectedCuisine] = useState(['0']);
  const [selectedNiche, setSelectedNiche] = useState('1');
  const [selectedCertification, setSelectedCertification] = useState(['0']);
  const [selectedAddonService, setSelectedAddonService] = useState(['0']);
  const [selectedEventType, setSelectedEventType] = useState(['0']);
  const [selectedState, setSelectedState] = useState(['0']);
  const [selectedRate, setSelectedRate] = useState('0');
  const [catererNameSearch, setCatererNameSearch] = useState('0');
  const [cityZipSearch, setCityZipSearch] = useState(params.qAtt === 'city' ? toURLDecode(params.qPara) : '0');
  const [pageNo, setPageNo] = useState(1);
  const [filterCountMobile, setFilterCountMobile] = useState(0);
  const [filterCount, setFilterCount] = useState(0);



  const { data: dataDietary, isLoading: isLoadingDietary } = useGetDietariesQuery()


  const { data: dataCompanyListing, isLoading: isLoadingCompanyListing } = useSearchCompanyListingQuery({ cuisineIds: selectedCuisine, certificationIds: selectedCertification, dietaryIds: selectedDietary, specialityIds: selectedSpeciality, servingOptIds: selectedServingOpt, eventTypeIds: selectedEventType, rateId: selectedRate, stateIds: selectedState, addonServiceIds: selectedAddonService, catererName: catererNameSearch, nicheIds: selectedNiche, userProfileId: AuthService.getUserProfileId(), cityZipSearch: cityZipSearch, pageNo: pageNo }, { skip: skipToken })
  const dataCompanyAll = dataCompanyListing?.payload ?? [];

  const [clearEventType, setClearEventType] = useState(undefined);
  const [clearSpecialty, setClearSpecialty] = useState(undefined);
  const [clearAddonServices, setClearAddonServices] = useState(undefined);

  const applyFilters = async () => {
    setPageNo((parseInt(pageNo) + 1).toString());
  };


  var nicheId, Id, pageTitle;

  useEffect(() => {
    if (isLoadingDietary === false) {
      setSkipToken(false);
      if (params.qNiche !== '' && params.qNiche !== undefined) {
        if (params.qNiche === 'restaurants-caterers')
          nicheId = '1,2'
        else if (params.qNiche === 'restaurants')
          nicheId = '1'
        else if (params.qNiche === 'caterers' && params.qAtt === 'cuisine')
          nicheId = '1,2'
        else if (params.qNiche === 'caterers')
          nicheId = '2'
        else if (params.qNiche === 'bakers')
          nicheId = '3'

        setSelectedNiche(nicheId);

        pageTitle = (params.qNiche == 'restaurants-caterers' ? 'Caterers' : toPascal(params.qNiche)) + " Near Me ";
      }


      if (params.qAtt !== '') {
        if (params.qAtt === 'cuisine') {
          pageTitle = toPascal(params.qPara) + " Restaurants Near Me ";
        }
        else if (params.qAtt === 'dietary') {
          Id = dataDietary.payload.find((d) => { return (d.dietaryName === toURLDecode(params.qPara)); }).dietaryId.toString();
          if (selectedDietary.indexOf(Id) === -1)
            setSelectedDietary([...selectedDietary, Id]);
          pageTitle = (params.qPara === "halal" ? "" : toPascal(params.qPara)) + " Restaurants Near Me ";
        }

        if (params.qLoc !== undefined && params.qLoc !== 'none') {
          document.getElementById('IdCityZipSearch').value = toURLDecode(params.qLoc);
          setCityZipSearch(toURLDecode(params.qLoc));
          pageTitle = "Restaurants in " + toPascal(params.qLoc);
        }

      }

      document.title = "Top 10 Halal " + pageTitle + " - Eat Halal";
    }
  }, [isLoadingDietary]);


  useEffect(() => {
    var updatedList;
    if (searchParams.get("Dietary")) {
      updatedList = searchParams.get("Dietary").split(",").map((n) => n)
    }
    if (updatedList !== undefined) {
      setSelectedDietary(updatedList)
    }

  }, []);


  const handleServingOptionSelection = (id) => {
    setPageNo(1)
    setSelectedServingOpt(id.length === 0 ? ['0'] : id);
    resetCompanyKeywordFilter();
  };

  const handleCertificationSelection = (id) => {
    setPageNo(1)
    setSelectedCertification(id.length === 0 ? ['0'] : id);
    resetCompanyKeywordFilter();
  };

  const handleAddonServiceSelection = (selected) => {
    setPageNo(1)
    resetCompanyKeywordFilter();
    if (selected.length === 0) {
      searchParams.delete("AddonService")
      setSelectedAddonService(['0']);
    }
    else {
      searchParams.set("AddonService", selected.map((s) => parseInt(s.value)));
      setSelectedAddonService(selected.map((s) => s.value.toString()));
    }
    setSearchParams(searchParams);
  };

  const handleEventTypeSelection = (selected) => {
    setPageNo(1)
    resetCompanyKeywordFilter();
    if (selected.length === 0) {
      searchParams.delete("EventType")
      setSelectedEventType(['0']);
    }
    else {
      searchParams.set("EventType", selected.map((s) => parseInt(s.value)));
      setSelectedEventType(selected.map((s) => s.value.toString()));
    }
    setSearchParams(searchParams);
  };

  const handleCuisineSelection = (selected) => {
    if (selected.length > 0) {
      setPageNo(1)
      resetCompanyKeywordFilter();

      setSelectedCuisine(selected.map((s) => s.toString()));
    }
    else
      setSelectedCuisine(['0']);
  };


  const handleSpecialitySelection = (selected) => {
    if (selected !== undefined) {
      setPageNo(1)
      resetCompanyKeywordFilter();

      if (selected.length === 0) {
        searchParams.delete("Speciality")
        setSelectedSpeciality(['0']);
      }
      else {
        searchParams.set("Speciality", selected.map((s) => parseInt(s.value)));
        setSelectedSpeciality(selected.map((s) => s.value.toString()));
      }
      setSearchParams(searchParams);
    }
  };

  const handleStateSelection = (id) => {
    setPageNo(1)
    setSelectedState(id.length === 0 ? ['0'] : id);
    resetCompanyKeywordFilter();
  };

  const onCompanySelection = (e) => {
    setPageNo(1);
    e !== null && resetSelectionFilters();
    setCatererNameSearch(e === null ? '0' : e.companyName);
  }

  const handleDietarySelection = (event) => {
    setPageNo(1)
    var updatedList = [...selectedDietary];
    if (event.target.checked) {
      updatedList = [...selectedDietary, event.target.value];
    } else {
      updatedList.splice(selectedDietary.indexOf(event.target.value), 1);
    }
    setSelectedDietary(updatedList);
    resetCompanyKeywordFilter();

    if (updatedList.filter(item => item !== '0').length === 0) {
      searchParams.delete("Dietary")
    }
    else {
      searchParams.set("Dietary", updatedList.map((s) => parseInt(s)));
    }
    setSearchParams(searchParams);
  };


  const handleNicheSelection = (event) => {
    setPageNo(1)
    setSelectedNiche(event.target.value);
    resetCompanyKeywordFilter();
  };

  function resetCompanyKeywordFilter() {
    setClearEventType();
    //ClearEventTypeSelection();
    setClearSpecialty();
    setClearAddonServices();

    setCatererNameSearch('0');
    ChildStateModificationFunc('');
  }

  function resetSelectionFilters() {
    setSelectedSpeciality(['0']);
    setSelectedServingOpt(['0']);

    setSelectedDietary(['0']);
    setSelectedCuisine(['0']);
    document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);

    setSelectedNiche(['0']);
    document.querySelectorAll('input[type=radio]').forEach(el => el.checked = false);

    setSelectedCertification(['0']);
    setSelectedAddonService(['0']);
    setSelectedEventType(['0']);
    setSelectedState(['0']);
    setSelectedRate('0');

    //setClearEventType(undefined);
    setClearEventType(null);

    // ClearEventTypeSelection();
    //setClearEventType2('karachi');

    setClearSpecialty(null);
    setClearAddonServices(null);
  }

  useEffect(() => {
    let fCountMobile = 0;
    let fCount = 0;

    if (selectedSpeciality.filter(item => item !== '0').length > 0)
      fCountMobile += 1;

    if (selectedEventType.filter(item => item !== '0').length > 0)
      fCountMobile += 1;

    if (selectedAddonService.filter(item => item !== '0').length > 0)
      fCountMobile += 1;

    if (selectedRate.toString() !== "0")
      fCountMobile += 1;

    if (selectedCuisine.filter(item => item !== '0').length > 0)
      fCountMobile += 1;

    if (selectedDietary.filter(item => item !== '0').length > 0)
      fCountMobile += 1;

    if (selectedServingOpt.filter(item => item !== '0').length > 0) {
      fCountMobile += 1;
      fCount += 1;
    }
    if (selectedCertification.filter(item => item !== '0').length > 0) {
      fCountMobile += 1;
      fCount += 1;
    }

    if (selectedState.filter(item => item !== '0').length > 0) {
      fCountMobile += 1;
      fCount += 1;
    }

    setFilterCountMobile(fCountMobile);
    setFilterCount(fCount);
  }, [selectedEventType, selectedSpeciality, selectedAddonService, selectedRate, selectedCuisine, selectedDietary, selectedServingOpt, selectedCertification, selectedState])


  const [view, setView] = useState('listing-grid');
  const [crtLoc, setCrtLoc] = useState('');
  useEffect(() => {
    AuthService.getLocation((data) => {
      var lat = data.coords.latitude;
      var long = data.coords.longitude;
      setCrtLoc({ latitude: lat, longitude: long })
    });
  }, []);

  return (

    <div>
      <Helmet prioritizeSeoTags>
        <link rel="canonical" href={getCurrentPathWithoutQueryParameters()} />
      </Helmet>
      {AuthService.getCurrentUser() === null && <ModalRegister />}

      <Navbar />


      <section className="pt-4* listing-options bg-pink">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12 listing-sidebar accordion">
              <div className="filters accordion accordion-flush sticky-top mb-4" id="accordionFilters">
                <div className="accordion-item">
                  <h2 className="accordion-header d-lg-none" id="filter-txt">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#filters" aria-expanded="true" aria-controls="filters">
                      <i className="fa-solid fa-filter me-2"></i> Filters ({filterCountMobile})
                    </button>
                  </h2>
                  <div id="filters" className="accordion-collapse collapse show" aria-labelledby="filter-txt" data-bs-parent="#accordionFilters">
                    <div className="accordion-body">
                      <h6 className="mt-3 d-lg-none">Filters</h6>
                      <div className="card search-box">
                        <div className="card-body">
                          <h5 className="card-title"><img src="/images/service-search.png" alt="" /> Search by Name<a href="/company-search" className="reset-btn">Reset</a></h5>
                          <CompanySelect handleSelection={onCompanySelection} />
                        </div>
                      </div>


                      <div className="card search-box">
                        <div className="card-body">
                          <h5 className="card-title"><img src="/images/marker.svg" width="20" height="20" alt="" /> Location</h5>
                          <form className="row input-search">

                            <div className="col-9">
                              <input onKeyDown={(e) => {
                                if (e.key === "Backspace")
                                  if (document.getElementById('IdCityZipSearch').value.length === 1)
                                    setCityZipSearch('0')
                              }} className="form-control" id="IdCityZipSearch" autoComplete="off" maxLength="12" placeholder="Enter zipcode or city" type="text" />
                            </div>
                            <div className="col-3 submit-btn">
                              <button className="btn btn-danger" type="submit" onClick={(e) => {
                                e.preventDefault();
                                setPageNo(1);
                                setCityZipSearch(document.getElementById('IdCityZipSearch').value.toString() === '' ? '0' : document.getElementById('IdCityZipSearch').value.toString());
                              }}><i className="fas fa-search"></i></button>
                            </div>

                          </form>
                        </div>
                      </div>

                      <AdShowcase selectedEventType={selectedEventType.toString()} selectedCuisine={selectedCuisine.toString()} selectedSpeciality={selectedSpeciality.toString()} selectedState={selectedState.toString()} />

                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title"><img src="/images/niche-icon.png" height="20" width="20" alt="Location" /> Search by Niche</h5>
                          <div className="mb-2">

                            <div className="form-check search-check">
                              <input checked={selectedNiche === '1,2'} className="form-check-input" value="1,2" type="radio" name="flexRadioDefault" id="RadiRestaurantAndCaterers" onChange={handleNicheSelection} />
                              <label className="form-check-label" htmlFor="RadiRestaurantAndCaterers">
                                Restaurant And Caterers Both
                              </label>
                            </div>
                            <div className="form-check search-check">
                              <input checked={selectedNiche === '1'} className="form-check-input" value="1" type="radio" name="flexRadioDefault" id="RadiRestaurantOnly" onChange={handleNicheSelection} />
                              <label className="form-check-label" htmlFor="RadiRestaurantOnly">
                                Restaurant Only
                              </label>
                            </div>
                            <div className="form-check search-check">
                              <input checked={selectedNiche === '2'} className="form-check-input" value="2" type="radio" name="flexRadioDefault" id="RadiCatererOnly" onChange={handleNicheSelection} />
                              <label className="form-check-label" htmlFor="RadiCatererOnly">
                                Caterer Only
                              </label>
                            </div>
                            <div className="form-check search-check">
                              <input checked={selectedNiche === '3'} className="form-check-input" value="3" type="radio" name="flexRadioDefault" id="RadioBakersOnly" onChange={handleNicheSelection} />
                              <label className="form-check-label" htmlFor="RadioBakersOnly">
                                Bakers Only
                              </label>
                            </div>

                          </div>
                        </div>
                      </div>


                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title"><img src="/images/dietaryIcon.svg" height="20" width="20" alt="" /> Search by Dietary</h5>
                          <div type="checkbox" className="mb-2 search-option-dietary">
                            {
                              isLoadingDietary ? <h1>loading...</h1> :
                                dataDietary.payload.map((dietaryData, index) => (
                                  <div key={index} className="py-1 row*">
                                    <input checked={selectedDietary.some(element => { return element === dietaryData.dietaryId.toString() })} value={dietaryData.dietaryId} className="btn-check* form-check-input" type="checkbox" id={'dietary' + index} onChange={handleDietarySelection} />
                                    <label htmlFor={'dietary' + index} className="btn* btn-primary* form-check-label ps-1"><img src={"/images/dietaryIcon_" + dietaryData.dietaryName.replace(" ", "") + ".png"} height="24" width="24" alt={dietaryData.dietaryName} />&nbsp;{dietaryData.dietaryName}</label>
                                  </div>
                                ))}
                          </div>
                        </div>
                      </div>

                      <div className="mb-4* d-flex flex-wrap gap-1 sticky-md-top filters-listing-topbar d-lg-none">
                        <div className="col-sm-12 cuisine-box accordion">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title"><img src="/images/cuisineIcon.svg" height="24" width="24" alt="" />Cuisine</h5>
                              <CuisineSelection CuisineSelectionHandler={(e) => handleCuisineSelection(e)} nicheId={selectedNiche}></CuisineSelection>
                            </div>
                          </div>
                        </div>

                        <div className="col-auto filter-select accordion">
                          <div className="card">
                            <div className="card-body">
                              <EventTypeSelection EventTypeSelectionHandler={handleEventTypeSelection} parentInput={clearEventType} ></EventTypeSelection>
                            </div>
                          </div>
                        </div>

                        <div className="col-auto filter-select accordion">
                          <div className="card">
                            <div className="card-body">
                              <SpecialitySelection SpecialitySelectionHandler={handleSpecialitySelection} parentInput={clearEventType}></SpecialitySelection>
                            </div>
                          </div>
                        </div>

                        <div className="col-auto  filter-select accordion">
                          <div className="card">
                            <div className="card-body">
                              <AddonServiceSelection AddonServiceSelectionHandler={handleAddonServiceSelection} parentInput={clearAddonServices}></AddonServiceSelection>
                            </div>
                          </div>
                        </div>

                        <div className="col-auto  filter-select accordion">
                          <div className="card">
                            <div className="card-body">
                              <select className="form-select rating" value={selectedRate} onChange={(e) => { setSelectedRate(e.target.value); setPageNo(1); }} aria-label="Default select example" name="searchRating">
                                <option value="0">Select Rating</option>
                                <option value="4">★★★★ & Up</option>
                                <option value="3">★★★ & Up</option>
                                <option value="2">★★ & Up</option>
                                <option value="1">★ & Up</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-auto filter-select accordion">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title"><img src="/images/distance.svg" alt="Location" /> Search by State</h5>
                              <StateSelection StateSelectionHandler={handleStateSelection} ></StateSelection>
                            </div>
                          </div>
                        </div>

                        <div className="col-auto  filter-select accordion">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title"><img src="/images/servingIcon.svg" height="24" width="24" alt="" /> Serving Options</h5>
                              <ServingOptionSelection ServingOptionSelectionHandler={handleServingOptionSelection}></ServingOptionSelection>
                            </div>
                          </div>
                        </div>

                        <div className="col-auto  filter-select accordion">
                          <div className="card">
                            <div className="card-body">
                              <h5 className="card-title"><img src="/images/certificateIcon.svg" height="24" width="24" alt="" /> Certifications</h5>
                              <CertificationSelection CertificationSelectionHandler={handleCertificationSelection} ></CertificationSelection>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                    <div className="accordion-footer  d-flex d-lg-none justify-content-between align-items-center">
                      <button className="reset-btn"><i className="fa-solid fa-rotate-left"></i> Reset</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>




            <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
              <div className="mb-4 d-flex flex-wrap gap-1 d-none d-lg-flex sticky-md-top filters-listing-topbar">
                <div className="col-sm-12 cuisine-box accordion">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title">
                        <h5><img src="/images/cuisineIcon.svg" height="24" width="24" alt="" />{(selectedNiche.toString() === '3') ? "Specilities" : "Cuisine"}</h5>
                        <CuisineSelection CuisineSelectionHandler={(e) => handleCuisineSelection(e)} nicheId={selectedNiche}></CuisineSelection>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto filter-select accordion">
                  <div className="card">
                    <div className="card-body">
                      <EventTypeSelection EventTypeSelectionHandler={handleEventTypeSelection} parentInput={clearEventType} ></EventTypeSelection>
                    </div>
                  </div>
                </div>
                {(selectedNiche.toString() !== '3') &&
                  <div className="col-auto filter-select accordion">
                    <div className="card">
                      <div className="card-body">
                        <SpecialitySelection SpecialitySelectionHandler={handleSpecialitySelection} parentInput={clearEventType}></SpecialitySelection>
                      </div>
                    </div>
                  </div>
                }
                <div className="col-auto  filter-select accordion">
                  <div className="card">
                    <div className="card-body">
                      <AddonServiceSelection AddonServiceSelectionHandler={handleAddonServiceSelection} parentInput={clearAddonServices}></AddonServiceSelection>
                    </div>
                  </div>
                </div>

                <div className="col-auto  filter-select accordion">
                  <div className="card">
                    <div className="card-body">
                      <select className="form-select rating" value={selectedRate} onChange={(e) => { setSelectedRate(e.target.value); setPageNo(1); }} aria-label="Default select example" name="searchRating">
                        <option value="0">Select Rating</option>
                        <option value="4">★★★★ & Up</option>
                        <option value="3">★★★ & Up</option>
                        <option value="2">★★ & Up</option>
                        <option value="1">★ & Up</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-auto accordion">
                  <button className="btn more-filter" data-bs-toggle="modal" data-bs-target="#moreFilterModal">More Filters {filterCount !== 0 && "(" + filterCount + ")"}<i className="fa fa-plus" aria-hidden="true" ></i></button>
                </div>



              </div>
              <div className="d-flex justify-content-end gap-2 mb-4">
                <button onClick={() => setView('list')} className="view-option-btn list-view"><i className="fa-solid fa-list"></i></button>
                <button onClick={() => setView('grid')} className="view-option-btn grid-view"><i className="fa-solid fa-grip"></i></button>
              </div>
              {/* more cuisine model */}

              <div className="more-filter-modal modal fade" id="moreFilterModal" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content p-4">
                    <div className="modal-header border-0 p-0 pb-4">
                      <h5 className="modal-title">More Filters</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title"><img src="/images/distance.svg" alt="Location" /> Search by State</h5>
                          <div className="mb-2*">
                            <StateSelection StateSelectionHandler={handleStateSelection} ></StateSelection>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title"><img src="/images/servingIcon.svg" height="24" width="24" alt="" /> Serving Options</h5>
                          <div className="form-check* mb-2*">
                            <ServingOptionSelection ServingOptionSelectionHandler={handleServingOptionSelection}></ServingOptionSelection>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-body">
                          <h5 className="card-title"><img src="/images/certificateIcon.svg" height="24" width="24" alt="" /> Certifications</h5>
                          <div className="form-check* mb-2*">
                            <CertificationSelection CertificationSelectionHandler={handleCertificationSelection} ></CertificationSelection>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>








              {isLoadingCompanyListing ?


                <div className="text-center">
                  <div className="mt-5 spinner-border text-danger" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
                :

                <>
                  {(dataCompanyAll.length === 0 && dataCompanyListing) && <div className="pt-3"><i><center><b>Oops... we didn't find anything that matches this search</b><br />Try to search for something more general, change your filters or check for spelling mistakes</center></i><hr /></div>}

                  <InfiniteScroll className="infinite-scroll" style={{ overflow: "unset" }}
                    dataLength={dataCompanyAll.length} //This is important field to render the next data
                    next={() => {
                      applyFilters();
                    }}
                    hasMore={true}
                  >
                    <div className={view === 'list' ? '' : 'listing-grid'}>
                      {view === 'list' ? (
                        dataCompanyAll.map((comp, index) => (
                          <CatererCard item={comp} key={index} />
                        ))
                      ) : (
                        dataCompanyAll.map((comp, index) => (
                          <CatererCardGrid item={comp} key={index} crtLoc={crtLoc} />
                        ))
                      )}
                    </div>
                  </InfiniteScroll>
                </>
              }

            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  )

}


export default CatererSearch