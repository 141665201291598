import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetCuisinesQuery, useGetSpecialitiesQuery } from '../../hooks/apiSlice';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { toURLDecode } from '../../hooks/util';
import Modal from 'react-bootstrap/Modal';

const CuisineSelection = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { data: dataCuisines, isLoading: isLoadingCuisines } = useGetCuisinesQuery()
    const { data: dataSpecialties, isLoading: isLoadingSpecialties } = useGetSpecialitiesQuery()
    const params = useParams();

    const [showMessageModel, setShowMessageModel] = useState(false);
    const [cuisineList, setCuisineList] = useState([]);
    const [specialityList, setSpecialityList] = useState([]);

    const handleCuisineSelection = (event) => {
        var updatedList = [...cuisineList];
        if (event.target.checked) {
            //updatedList = [...checkedCuisine, parseInt(event.target.value)];
            updatedList = updatedList.map(obj => { if (obj.cuisineId === parseInt(event.target.value)) { return { ...obj, isSelected: true }; } return obj; })
        } else {
            updatedList = updatedList.map(obj => { if (obj.cuisineId === parseInt(event.target.value)) { return { ...obj, isSelected: false }; } return obj; })
        }

        setCuisineList(updatedList)
        //setCheckedCuisine(updatedList);
        props.CuisineSelectionHandler(updatedList.filter((item) => item.isSelected === true).map((e) => e.cuisineId.toString()));


        if (updatedList.length === 0)
            searchParams.delete("Cuisine")
        else
            searchParams.set("Cuisine", updatedList.filter((item) => item.isSelected === true).map((e) => e.cuisineId.toString()));

        setSearchParams(searchParams);

        // setCuisineList(cuisineList.map(obj => {
        //     if (obj.cuisineId.toString() === event.target.value) {
        //         return { ...obj, isSelected: true };
        //     }
        //     return obj;
        // }));
    };


    const handleSpecialitySelection2 = (event) => {
        var updatedList = [...specialityList];
        if (event.target.checked) {
            updatedList = updatedList.map(obj => { if (obj.specialityId === parseInt(event.target.value)) { return { ...obj, isSelected: true }; } return obj; })
            //updatedList = [...checkedSpeciality, parseInt(event.target.value)];
        } else {
            updatedList = updatedList.map(obj => { if (obj.specialityId === parseInt(event.target.value)) { return { ...obj, isSelected: false }; } return obj; })
            //updatedList.splice(specialityList.indexOf(parseInt(event.target.value)), 1);
        }
        setSpecialityList(updatedList)
        props.CuisineSelectionHandler(updatedList.filter((item) => item.isSelected === true).map((e) => e.specialityId.toString()));

        if (updatedList.length === 0)
            searchParams.delete("Speciality")
        else
            searchParams.set("Speciality", updatedList.filter((item) => item.isSelected === true).map((e) => e.specialityId.toString()));

        setSearchParams(searchParams);
    };



    useEffect(() => {

        if (isLoadingCuisines === false) {
            let aryCuisine = dataCuisines.payload.map(function (item, i) {
                return { cuisineId: item.cuisineId, cuisineName: item.cuisineName, isSelected: false, displayOrder: item.displayOrder };
            })

            if (params.qAtt === 'cuisine') {
                //setCheckedCuisine([dataCuisines.payload.find((obj) => { return (obj.cuisineName === toURLDecode(params.qPara))}).cuisineId]);
                aryCuisine = aryCuisine.map(obj => { if (obj.cuisineName === toURLDecode(params.qPara)) { return { ...obj, isSelected: true }; } return obj; })
                //props.CuisineSelectionHandler([dataCuisines.payload.find((obj) => { return (obj.cuisineName === toURLDecode(params.qPara)) }).cuisineId]);
            }

            if (searchParams.get("Cuisine")) {
                aryCuisine = aryCuisine.map((obj) => {
                    if (searchParams.get("Cuisine").split(",").filter((item) => item === obj.cuisineId.toString()).length > 0)
                        return { ...obj, isSelected: true };
                    else
                        return obj
                });
                //props.CuisineSelectionHandler(searchParams.get("Cuisine").split(",").map((n) => parseInt(n)));
            }
            props.CuisineSelectionHandler(aryCuisine.filter((item) => item.isSelected === true).map((n) => parseInt(n.cuisineId)));
            setCuisineList(aryCuisine);
        }

    }, [isLoadingCuisines]);


    useEffect(() => {

        if (isLoadingSpecialties === false) {
            let arySpeciality = dataSpecialties.payload.filter((m) => m.nicheId === 3).map(function (item, i) {
                return { specialityId: item.specialityId, specialityName: item.specialityName, isSelected: false };
            })

            if (params.qAtt === 'speciality') {
                arySpeciality = arySpeciality.map(obj => { if (obj.specialityName === toURLDecode(params.qPara)) { return { ...obj, isSelected: true }; } return obj; })
                //setCheckedSpeciality([dataSpecialties.payload.find((obj) => { return (obj.specialityName === toURLDecode(params.qPara)) }).specialityId]);
                //props.CuisineSelectionHandler([dataSpecialties.payload.find((obj) => { return (obj.specialityName === toURLDecode(params.qPara)) }).specialityId]);
            }

            if (searchParams.get("Speciality")) {
                arySpeciality = arySpeciality.map((obj) => {
                    if (searchParams.get("Speciality").split(",").filter((item) => item === obj.specialityId.toString()).length > 0)
                        return { ...obj, isSelected: true };
                    else
                        return obj
                });
                //setCheckedSpeciality(searchParams.get("Speciality").split(",").map((n) => parseInt(n)));
                //props.CuisineSelectionHandler(searchParams.get("Speciality").split(",").map((n) => parseInt(n)));
            }

            //props.CuisineSelectionHandler(arySpeciality.filter((item) => item.isSelected === true).map((n) => parseInt(n.specialityId)));
            setSpecialityList(arySpeciality);

        }
    }, [isLoadingSpecialties]);





    return (
        <>
            <ToggleButtonGroup type="checkbox" className="mb-2 more-cuisine-items" vertical>
                {
                    <div>
                        {
                            (props.nicheId.toString() === '3') ?
                                //isLoadingSpecialties ? <h1>loading...</h1> :
                                specialityList.map((specialityData, index) =>
                                    <div key={index}>
                                        <label className="label-box">
                                            <input checked={specialityData.isSelected} value={specialityData.specialityId} className="btn-check" type="checkbox" onChange={handleSpecialitySelection2} />
                                            <span className="btn-label btn">{specialityData.specialityName}</span>
                                        </label>
                                    </div>
                                )
                                :
                                <div>
                                    {cuisineList.sort(
                                        function (a, b) {
                                            if (a.isSelected === b.isSelected) {
                                                return b.displayOrder - a.displayOrder;
                                            }
                                            return a.isSelected > b.isSelected ? 1 : -1;
                                        }).reverse().slice(0, 6).map((cuisineData, index) =>
                                            <div key={index}>
                                                <label className="label-box">
                                                    {/* <input checked={checkedCuisine.some(element => { return element === cuisineData.cuisineId })} value={cuisineData.cuisineId} className="btn-check" type="checkbox" onChange={handleCuisineSelection} /> */}
                                                    <input checked={cuisineData.isSelected} value={cuisineData.cuisineId} className="btn-check" type="checkbox" onChange={handleCuisineSelection} />
                                                    <span className="btn-label btn">{cuisineData.cuisineName}</span>
                                                </label>
                                            </div>
                                        )}
                                </div>
                        }
                    </div>
                }

                {(props.nicheId.toString() !== '3') && <button href="#" onClick={() => { setShowMessageModel(true) }} className="btn border mt-2* w-100* more-cuisine-btn" >More Cuisines <i className="fas fa-search"></i></button>}

            </ToggleButtonGroup>

            <Modal show={showMessageModel} >
                <Modal.Header>
                    <Modal.Title>
                        <img src="/images/cuisineIcon.svg" height="24" width="24" alt="" />More {(props.nicheId.toString() === '3') ? "Specilities" : "Cuisine"}
                    </Modal.Title>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                        setShowMessageModel(false);
                    }}></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title"><img src="/images/cuisineIcon.svg" height="24" width="24" alt="" />{(props.nicheId.toString() === '3') ? "Specilities" : "Cuisine"}</h5>
                            <div className="mb-2*">
                                <ToggleButtonGroup type="checkbox" className="mb-2" vertical>
                                    {
                                        <div>
                                            {
                                                //isLoadingCuisines ? <h1>loading...</h1> :
                                                <div>
                                                    {cuisineList.sort(
                                                        function (a, b) {
                                                            if (a.isSelected === b.isSelected) {
                                                                return b.displayOrder - a.displayOrder;
                                                            }
                                                            return a.isSelected > b.isSelected ? 1 : -1;
                                                        }).reverse().map((cuisineData, index) =>
                                                            <div key={index}>
                                                                <label className="label-box">
                                                                    <input checked={cuisineData.isSelected} value={cuisineData.cuisineId} className="btn-check" type="checkbox" onChange={handleCuisineSelection} />
                                                                    <span className="btn-label btn">{cuisineData.cuisineName}</span>
                                                                </label>
                                                            </div>
                                                        )}
                                                </div>
                                            }
                                        </div>
                                    }
                                </ToggleButtonGroup>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-danger" onClick={() => {
                        setShowMessageModel(false);
                    }}>Close</button>
                </Modal.Footer>
            </Modal>
        </>
    )
};



export default CuisineSelection;