import { useState } from "react";
import { useToggleFavoriteMutation } from '../hooks/apiSlice';
import AuthService from "../hooks/Auth";
import RatingStar from "./RatingStar"

const CatererCard = ({ item }) => {

  const [favoriteStatus, setFavoriteStatus] = useState(item.isFavorite);
  const [toggleFavoriteMutation] = useToggleFavoriteMutation();
  const [showContact, setShowContact] = useState(false);


  const Favorite = (e) => {
    return (
      <>
        {item.isFavorite || favoriteStatus ? <i style={{ 'cursor': 'pointer' }} title="remove from your favorites" className="text-danger fa-solid fa-heart"
          onClick={
            () => {
              toggleFavoriteMutation({ 'company.companyId': item.companyId, 'userProfile.userProfileId': AuthService.getUserProfileId(), status: !favoriteStatus })
              setFavoriteStatus(!favoriteStatus)
            }
          }></i> : <i style={{ 'cursor': 'pointer' }} title="add to your favorites" className="fa-regular fa-heart"
            onClick={
              () => {
                toggleFavoriteMutation({ 'company.companyId': item.companyId, 'userProfile.userProfileId': AuthService.getUserProfileId(), status: !favoriteStatus })
                setFavoriteStatus(!favoriteStatus)
              }
            }></i>
        }
      </>
    )
  }


  return (
    <div className="row justify-content-center mb-3 listing-box">
      <div className="col-xl-12">
        <div className={(item.productPlanName === "PREMIUM") ? "card premium-listing shadow-0 border rounded-3" : "card regular-listing shadow-0 border rounded-3"}>
          <div className="card-body">
            <div className="row box">

              <div className="col-6 col-sm-5 col-md-4 col-lg-4 col-xl-3 mb-4* mb-md-0">
                <div className="rounded listing-image text-center">
                  {
                    (item.logoFileName) ?
                      <img src={process.env.REACT_APP_MEDIA_STORAGE + "logo/" + item.logoFileName} alt={item.companyName} style={{ 'maxHeight': '200px', 'maxWidth': '200px' }} />
                      :
                      <div className="picture-placeholder">
                        <div>{item.companyName.substring(0, 1)}</div>
                      </div>
                  }
                </div>
              </div>

              <div className="col-6 col-sm-7 col-md-8 col-lg-8 col-xl-9 info">
                <div className="row listing-head">
                  <div className="col-12 col-sm-7 col-lg-8 col-md-8">
                    <h5><a className="RegularLink" href={'/company-detail/' + item.companyId + '/' + item.locationSlug + '/' + item.companySlug}>{item.companyName}</a>

                      {(item.isVerified)
                        &&
                        <img src="/images/vetted-icon.svg" alt="verified by HalalCater" className="ps-2" />
                      }
                    </h5>
                    <div className="d-flex flex-column align-items-start flex-md-row align-items-md-center w-100">
                      {(item.productPlanName !== null)
                        &&
                        <div className={item.productPlanName === "PREMIUM" ? "list-badge yellow-bg" : "list-badge blue-bg"}>
                          {item.productPlanName}
                        </div>
                      }
                      <div className="contact-info">
                        <div className="cityname mb-1* me-md-2">
                          <i className="fa-solid fa-location-dot me-1"></i> {item.city && item.city}, {item.stateName && item.stateName}{item.zipCode !== null && ', ' + item.zipCode}
                          </div>
                        <div className="d-inline-block align-middle business-info mb-1* me-2*">

                          {(item.website !== '')
                            &&
                            <a className="IconLink" href={item.website} target="_blank"> <i className=' fas fa-globe mx-2' title={item.website}></i></a>
                          }

                          {(item.website !== '' && item.contactNumber !== '')
                            &&
                            <span>|</span>
                          }
                          {(item.contactNumber !== null)
                            &&
                            <div className="d-inline-block fw-bold" onClick={() => setShowContact(true)}>
                            {showContact ? <div className='ms-2'>{item.contactNumber}</div> : <i style={{ 'cursor': 'pointer' }} className='fas fa-phone ms-2' title="click to view contact"></i> }
                            </div>
                          }
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="col-12 col-sm-5 col-lg-4 col-md-4 mt-md-1 d-flex position-relative* justify-content-between">
                    
                 
                    {item.companyRatingAverage > 0 ?
                      <div className="review-box">
                        <div className="red-bg">
                          <p style={{ 'fontWeight': '600' }}>
                            {item.companyRatingAverage}
                          </p>
                          &nbsp;
                          <RatingStar starCount={item.companyRatingAverage}/>
                          <span className="total-count">({item.companyRatingCount})</span>
                        </div>
                        <span className="niche">{item.niche === 'Restaurant,Caterer' ? 'Restaurant & Caterer Both' : item.niche + ' Only'}</span>
                      </div>
                      :
                      <div className="no-reviews text-center ps-lg-3">
                        <p className="margin0">New on Halal Caters</p>
                        <span className="niche">{item.niche === 'Restaurant,Caterer' ? 'Restaurant & Caterer Both' : item.niche + ' Only'}</span>
                      </div>
                    }
                    <div className="d-none* d-sm-block* cat-save-icon float-end">
                      {AuthService.getCurrentUser() !== null && <Favorite company={item} />}
                    </div>
                  </div>
                  {item.dietaryCSV &&
                    <div className="col-auto caterer-card-dietary-box">
                      <div className="row margin0 gap-1" >
                        {(item.dietaryCSV.split(',').filter(Boolean).map((e, index) => <div key={index} className="col-auto caterer-dietry-icons px-1"><img src={"/images/dietaryIcon_" + e.replace(" ", "") + ".png"} height="20" width="20" alt={e} title={e} /><span>{e}</span></div>))}
                      </div>
                    </div>
                  }
                </div>

                <div className="ms-0 ms-md-1 mt-1 mt-md-4 row listing-specs">
                  <div className="col-lg-9 col-md-9 col-sm-12 col-12 info* px-0 px-sm-3 ps-md-2 pe-lg-5">
                  <div className="row info ps-2 pb-1">
                  {item.eventTypeCSV != null && 
                    <div className="col-lg-3 padding0 mt-2 col-md-3 col-sm-4 col-12 info">
                      <p>Event Type <span>({item.eventTypeCount})</span></p>
                      <div className="items">
                        <div>
                          {item.eventTypeCSV}
                          {item.eventTypeCount > 1 && <span>...</span>}
                        </div>
                      </div>
                    </div>
                  }

                {item.cuisineCSV != null &&
                    <div className="col-lg-3 col-md-3 col-sm-4 col-12 padding0 mt-2 info">
                      <p>Cuisine <span>({item.cuisineCount})</span></p>
                      <div className="items">
                        <div>
                          {item.cuisineCSV}
                          {item.cuisineCount > 1 && <span>...</span>}
                        </div>
                      </div>
                    </div>
                  }


                  {item.specialityCSV != null &&
                    <div className="col-lg-3 col-md-3 col-sm-4 col-12 padding0 mt-2 info">
                      <p>Specialty <span>({item.specialityCount})</span></p>
                      <div className="items">
                        <div>
                          {item.specialityCSV}
                          {item.specialityCount > 1 && <span>...</span>}
                        </div>
                      </div>
                    </div>
                  }
                  </div>
                  </div>
                  <div className="col-lg-3 mt-md-2 col-md-3 col-sm-12 view-profile">
                    <button type="button" className="btn btn-secondary rounded-pill"><a href={'/company-detail/' + item.companyId + '/' + item.companySlug}>View Profile</a></button>
                  </div>
                  
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default CatererCard;