import { useGetTargetedSubscriptionDetailQuery } from './../hooks/apiSlice';

const AdBoost = (props) => {

    const { data: dataTargetedSubscriptionDetail, isLoading: isLoadingTargetedSubscriptionDetail } = useGetTargetedSubscriptionDetailQuery({ eventTypeCSV: props.para.selectedEventType, cuisinCSV: props.para.selectedCuisine, specialityCSV: props.para.selectedSpeciality, state: props.para.selectedState, productPlanIdCSV: 15 })

    return (
        <>
            {/* {isLoadingTargetedSubscriptionDetail ? "Loading..." : dataTargetedSubscriptionDetail.payload != null && dataTargetedSubscriptionDetail.payload.subscriptionId + "-" + dataTargetedSubscriptionDetail.payload.subscriptionDetailId + "-" + dataTargetedSubscriptionDetail.payload.imageFileName + "-" + dataTargetedSubscriptionDetail.payload.title} */}

            {isLoadingTargetedSubscriptionDetail ? "Loading..." : <>
                {console.log(dataTargetedSubscriptionDetail)}
                {/* {props.selectedEventType}-
         {props.selectedCuisine}-
         {props.selectedSpeciality}-
         {props.selectedState} */}
                {dataTargetedSubscriptionDetail.payload != null && <div className="card search-box"><a href={dataTargetedSubscriptionDetail.payload.url} target="_blank" ><img src={process.env.REACT_APP_BASE_URL + "MediaStorage/Boost/" + dataTargetedSubscriptionDetail.payload.imageFileName} alt={dataTargetedSubscriptionDetail.payload.title} title={dataTargetedSubscriptionDetail.payload.title} style={{ 'maxWidth': '309px' }} /></a></div>}
            </>
            }
        </>
    )
}

const AdBanner = (props) => {

    return (
        <>
            {props.para != null && <div className="card search-box"><a href={props.para.url} target="_blank" ><img src={process.env.REACT_APP_BASE_URL + "MediaStorage/BannerAd/" + props.para.imageFileName} alt={props.para.title} title={props.para.title} style={{ 'maxWidth': '416' }} /></a></div>}
        </>
    )
}



const AdShowcase = (props) => {

    return (
        <>
            {props.ProductPlan === "12,14" ? <AdBanner para={props} /> : <AdBoost para={props}/>}
        </>
    )
}

export default AdShowcase