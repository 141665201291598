import { toast } from 'react-toastify';
import {
    useSaveCustomDealMutation, useSaveFlatDealMutation, useGetFlatDealQuery, useGetCustomDealQuery//, useDeleteDealMutation, useUpdateDealMutation, useGetDealQuery
    , useGetAllPromotionCategoryQuery
} from './../../hooks/apiSlice';
import AuthService from "./../../hooks/Auth";
import React, { useState, useEffect } from "react";
import MaskedInput from 'react-text-mask'
import DeleteConfirmation from '../../hooks/Confirmation';
import moment from 'moment'
import { valideUrl } from '../../hooks/util';
import Modal from 'react-bootstrap/Modal';

const FlatDeal = () => {

    const [includeExpired, setIncludeExpired] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);
    const [page, setPage] = useState(1)

    const { data: dataFlatDeal, isLoading: isLoadingFlatDeal, isFetching } = useGetFlatDealQuery({ dealPromotionCategoryId: selectedCategoryId, includeExpired: includeExpired, pageNum: page })
    const { data: dataCategory, isLoading: isLoadingCategory } = useGetAllPromotionCategoryQuery()

    const [saveFlatDealMutation] = useSaveFlatDealMutation()


    const [showModel, setShowModel] = useState(false);
    const [dealId, setDealId] = useState(0);
    const [discountPer, setDiscountPer] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [categoryId, setCategoryId] = useState("0");
    const [url, setUrl] = useState('');
    const [status, setStatus] = useState(true);
    const [note, setNote] = useState('');
    const [isExclusiveDeal, setIsExclusiveDeal] = useState(true);

    const saveFlatDeal = () => {
        saveFlatDealMutation({ dealId: dealId, dealPromotionCategoryId: isExclusiveDeal ? categoryId : null, note: note, uRL: url, startOn: startDate, expiresOn: endDate, PercentageOff: discountPer, isActive: status });
    }
    return (

        <>
            <div className="row p-3">
                <div className="col-12">
                    <h4>Offer Flat Discounted Deal</h4>
                    <button className="btn deal-btn" onClick={() => {
                        setShowModel(true);
                        setDealId(0);
                        setDiscountPer('0');
                        setStartDate('');
                        setEndDate('');
                        setUrl('');
                        setCategoryId('0');
                        setStatus(false);
                        setNote('');
                    }}>Create Flat Deal <i className="fa fa-plus" aria-hidden="true"></i></button>
                </div>
            </div>


            {!isLoadingFlatDeal && dataFlatDeal.data.length > 0 &&
                <div className="row p-3">
                    <div className="col-12">
                        <div>
                            <div className="row align-items-center">
                                {/* <div className="col-sm-6">
                                    {!isLoadingCategory &&
                                        <select className="form-select"
                                            onChange={(e) => setSelectedCategoryId(e.target.value)}>
                                            <option value="0" >Select Category</option>
                                            {
                                                dataCategory.payload.map((st, index) =>
                                                    <option key={index} value={st.dealPromotionCategoryId} >{st.dealPromotionCategoryName}</option>
                                                )
                                            }
                                        </select>}
                                </div> */}
                                <div className="col-sm-6">
                                    <input type="checkbox" id="showExpired1" onChange={(e) => setIncludeExpired(e.target.checked)} className='form-check-input'></input>
                                    <label className="form-check-label ps-1" htmlFor="showExpired1">Show Expired</label>
                                </div>
                            </div>
                            <br />
                            {
                                !isLoadingFlatDeal &&
                                <div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Date Created</th>
                                                <th>Discount</th>
                                                <th>Start On</th>
                                                <th>Expires On</th>
                                                <th>Status</th>
                                                <th>URL</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {

                                                dataFlatDeal.data.map((deal, index) =>
                                                    <tr key={index}>
                                                        <td className="align-middle">{moment(deal.createdOn).format("MM-DD-YYYY")}</td>
                                                        <td className="align-middle"><b>{deal.percentageOff} %</b><br/>{deal.dealPromotionCategoryName}</td>
                                                        <td className="align-middle"><i className="fa-solid fa-calendar-check"></i> {moment(deal.startOn).format("MM-DD-YYYY")}</td>
                                                        <td className="align-middle"><i className="fa-solid fa-calendar-xmark"></i> {moment(deal.expiresOn).format("MM-DD-YYYY")}&nbsp;{(moment(deal.expiresOn) < moment(new Date())) && <span className="badge rounded-pill bg-warning text-dark">expired</span>}</td>
                                                        <td className="align-middle"><i className={deal.status ? "fa-sharp fa-solid fa-circle-check ms-1 me-2" : "fa-sharp fa-solid fa-circle-xmark ms-1 me-2"}></i></td>
                                                        <td className="align-middle">{deal.url !== null && <a href={deal.url} target='blank'><i className="fa-solid fa-globe"></i></a>}</td>
                                                        <td className="align-middle"><i className="fa-solid fa-square-poll-horizontal" placeholder='Edit' style={{ cursor: 'pointer' }} onClick={(e) => {
                                                            setShowModel(true);
                                                            setDealId(deal.dealId);
                                                            setDiscountPer(deal.percentageOff);
                                                            setStartDate(moment(deal.startOn).format("YYYY-MM-DD").toString());
                                                            setEndDate(moment(deal.expiresOn).format("YYYY-MM-DD").toString());
                                                            setUrl(deal.url === null ? '' : deal.url);
                                                            setCategoryId(deal.dealPromotionCategoryId);
                                                            setStatus(deal.status);
                                                            setNote(deal.note === null ? '' : deal.note);
                                                            setIsExclusiveDeal(deal.dealPromotionCategoryId !== null) 
                                                        }}></i></td>
                                                    </tr>
                                                )}

                                        </tbody>
                                    </table>
                                    <br />
                                    <div className="pagination justify-content-center align-items-center pb-3">

                                        <button className="prev-btn"
                                            onClick={() => setPage((prev) => prev - 1)}
                                            disabled={page === 1 || isFetching}
                                        >
                                            {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-left"></i>}
                                        </button>
                                        <button className="next-btn"
                                            onClick={() => setPage((prev) => prev + 1)}
                                            disabled={page >= dataFlatDeal.totalPages || isFetching}
                                        >
                                            {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-right"></i>}
                                        </button>
                                        <div className="page px-1">
                                            {`${page} / ${dataFlatDeal.totalPages}`}
                                        </div>
                                    </div>
                                </div>
                            }



                        </div>
                    </div>
                </div>
            }
            <Modal show={showModel} >
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title">{dealId === 0 ? "Create" : "Edit"} Flat Deal</h5>
                    </Modal.Title>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => setShowModel(false)} ></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="deal-form modal-body">
                        <div className="row ">
                            <div className="col-12 mt-3">
                                <label htmlFor="discount">Discount %</label>
                                <select id="discount" className={discountPer === "0" ? "form-select border border-danger" : "form-select"}
                                    onChange={(e) => setDiscountPer(e.target.value)} value={discountPer}>
                                    <option value="0">Select Discount</option>
                                    <option value="10">10%</option>
                                    <option value="20">20%</option>
                                    <option value="30">30%</option>
                                    <option value="40">40%</option>
                                    <option value="50">50%</option>
                                    <option value="60">60%</option>
                                    <option value="70">70%</option>
                                    <option value="80">80%</option>
                                    <option value="90">90%</option>
                                </select>
                            </div>
                            <div className="col-6 mt-3">
                                <label htmlFor="startDate">Start Date</label>
                                <input type="date"
                                    onChange={(e) => setStartDate(e.target.value)}
                                    required
                                    value={startDate}
                                    className={startDate === "" ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"} />
                            </div>
                            <div className="col-6 mt-3">
                                <label htmlFor="endDate">End Date</label>
                                <input type="date"
                                    onChange={(e) => setEndDate(e.target.value)}
                                    required
                                    value={endDate}
                                    className={endDate === "" ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"} />
                            </div>

                            <div className="col-12 border border-success rounded my-3 py-2">
                                <div className="col-12">
                                    <input onChange={(e) => setIsExclusiveDeal(e.target.checked)} checked={isExclusiveDeal} type="checkbox" id="exclusiveDeal" className="form-check-input" />&nbsp;
                                    <label className="form-check-label pe-1" htmlFor="exclusiveDeal">Publish this in HalalCaters Exclusive deal page</label>
                                </div>
                                {isExclusiveDeal &&
                                    <div className="col-12 mt-3">
                                        <label htmlFor="tag">Select HalalCates Exclusive Deal category</label>
                                        {!isLoadingCategory &&
                                            <select className={categoryId === "0" ? "form-select border border-danger" : "form-select"}
                                                onChange={(e) => setCategoryId(e.target.value)} value={categoryId}>
                                                <option value="0" >Select</option>
                                                {
                                                    dataCategory.payload.map((st, index) =>
                                                        <option key={index} value={st.dealPromotionCategoryId} >{st.dealPromotionCategoryName}</option>
                                                    )
                                                }
                                            </select>
                                        }
                                    </div>
                                }
                            </div>

                            <div className="col-12 mt-3">
                                <label htmlFor="url">URL</label>
                                <input type="text"
                                    onChange={(e) => setUrl(e.target.value)}
                                    required
                                    value={url}
                                    maxLength="120" />
                            </div>

                            <div className="col-12 mt-3 d-flex align-items-center">
                                <label className=" form-check-label pe-1" htmlFor="isActive3">Active: </label>
                                <input onChange={(e) => setStatus(e.target.checked)} checked={status} type="checkbox" id="isActive3" className="form-check-input" />
                            </div>

                            <div className="col-12 mt-3">
                                <label className=" form-check-label pe-1" htmlFor="note">Notes </label>
                                <textarea id="note" value={note} onChange={(e) => setNote(e.target.value.slice(0, 1000))} rows="4"></textarea>
                            </div>

                            <div className="col-12 mt-3">
                                <button className="btn btn-danger" onClick={() => {
                                    saveFlatDeal();
                                    setShowModel(false);
                                }}
                                    disabled={(isExclusiveDeal && (categoryId === null || categoryId === '0')) || discountPer === '0' || startDate === "" || endDate === "" || !valideUrl(url)}>Save</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}




const CustomDeal = () => {

    const [includeExpired, setIncludeExpired] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(0);
    const [page, setPage] = useState(1)

    const { data: dataCustomDeal, isLoading: isLoadingCustomDeal, isFetching } = useGetCustomDealQuery({ dealPromotionCategoryId: selectedCategoryId, includeExpired: includeExpired, pageNum: page })
    const { data: dataCategory, isLoading: isLoadingCategory } = useGetAllPromotionCategoryQuery()

    const [saveCustomDealMutation] = useSaveCustomDealMutation()


    const [showModel, setShowModel] = useState(false);
    const [dealId, setDealId] = useState(0);
    const [dealName, setDealName] = useState('');
    const [imageFileName, setImageFileName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [categoryId, setCategoryId] = useState("0");
    const [url, setUrl] = useState('');
    const [status, setStatus] = useState(true);
    const [note, setNote] = useState('');

    const [currentFile, setCurrentFile] = useState(undefined);
    const [previewImage, setPreviewImage] = useState(undefined);
    const [isExclusiveDeal, setIsExclusiveDeal] = useState(true);

    const saveCustomDeal = () => {
        saveCustomDealMutation({ dealId: dealId, dealPromotionCategoryId: isExclusiveDeal ? categoryId : null, note: note, uRL: url, startOn: startDate, expiresOn: endDate, dealName: dealName, isActive: status, imageFileName: currentFile, file: currentFile });
    }

    const selectFile = (event) => {
        if (event.target.files[0].size > 1048576)
            alert('Image file should not exceeds 1MB in size')
        else {
            setCurrentFile(event.target.files[0]);
            setPreviewImage(URL.createObjectURL(event.target.files[0]));
        }
    };


    return (

        <>
            <div className="row p-3">
                <div className="col-12">
                    <h4>Offer Custom Deal</h4>
                    <button className="btn deal-btn" onClick={() => {
                        setShowModel(true);
                        setDealId(0);
                        setDealName('');
                        setStartDate('');
                        setEndDate('');
                        setUrl('');
                        setCategoryId('0');
                        setStatus(false);
                        setNote('');
                        setCurrentFile('')
                        setPreviewImage(undefined)
                    }}>Create Custom Deal <i className="fa fa-plus" aria-hidden="true"></i></button>
                </div>
            </div>
            {!isLoadingCustomDeal && dataCustomDeal.data.length > 0 &&
                <div className="row p-3">
                    <div className="col-12">
                        <div>
                            <div className="row align-items-center">
                                {/* <div className="col-sm-6">
                                    {!isLoadingCategory &&
                                        <select className="form-select"
                                            onChange={(e) => setSelectedCategoryId(e.target.value)}>
                                            <option value="0" >Select Category</option>
                                            {
                                                dataCategory.payload.map((st, index) =>
                                                    <option key={index} value={st.dealPromotionCategoryId} >{st.dealPromotionCategoryName}</option>
                                                )
                                            }
                                        </select>}
                                </div> */}
                                <div className="col-sm-6">
                                    <input type="checkbox" id="showExpired3" onChange={(e) => setIncludeExpired(e.target.checked)} className='form-check-input'></input>
                                    <label className="form-check-label ps-1" htmlFor="showExpired3" >Show Expired</label>
                                </div>
                            </div>
                            <br />
                            {
                                !isLoadingCustomDeal &&
                                <div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Date Created</th>
                                                <th>Deal Name</th>
                                                <th>Image</th>
                                                <th>Start On</th>
                                                <th>Expires On</th>
                                                <th>Status</th>
                                                <th>URL</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {

                                                dataCustomDeal.data.map((deal, index) =>
                                                    <tr key={index}>
                                                        <td className="align-middle">{moment(deal.createdOn).format("MM-DD-YYYY")}</td>
                                                        <td className="align-middle"><b>{deal.dealName}</b><br/>{deal.dealPromotionCategoryName}</td>
                                                        <td className="align-middle"><img className="item-img border-0" src={process.env.REACT_APP_BASE_URL + "MediaStorage/DealImages/" + (deal.imageFileName)} alt={deal.dealName} style={{'maxWidth':'120px'}} /></td>
                                                        <td className="align-middle"><i className="fa-solid fa-calendar-check"></i> {moment(deal.startOn).format("MM-DD-YYYY")}</td>
                                                        <td className="align-middle"><i className="fa-solid fa-calendar-xmark"></i> {moment(deal.expiresOn).format("MM-DD-YYYY")}&nbsp;{(moment(deal.expiresOn) < moment(new Date())) && <span className="badge rounded-pill bg-warning text-dark">expired</span>}</td>
                                                        <td className="align-middle"><i className={deal.status ? "fa-sharp fa-solid fa-circle-check ms-1 me-2" : "fa-sharp fa-solid fa-circle-xmark ms-1 me-2"}></i></td>
                                                        <td className="align-middle">{deal.url !== null && <a href={deal.url} target='blank'><i className="fa-solid fa-globe"></i></a>}</td>
                                                        <td className="align-middle"><i className="fa-solid fa-square-poll-horizontal" placeholder='Edit' style={{ cursor: 'pointer' }} onClick={(e) => {
                                                            setShowModel(true);
                                                            setDealId(deal.dealId);
                                                            setDealName(deal.dealName);
                                                            setStartDate(moment(deal.startOn).format("YYYY-MM-DD").toString());
                                                            setEndDate(moment(deal.expiresOn).format("YYYY-MM-DD").toString());
                                                            setUrl(deal.url === null ? '' : deal.url);
                                                            setCategoryId(deal.dealPromotionCategoryId);
                                                            setStatus(deal.status);
                                                            setNote(deal.note === null ? '' : deal.note);
                                                            setCurrentFile(deal.imageFileName);
                                                            setPreviewImage(undefined)
                                                            setIsExclusiveDeal(deal.dealPromotionCategoryId !== null) 
                                                        }}></i>
                                                        </td>
                                                    </tr>
                                                )}

                                        </tbody>
                                    </table>
                                    <br />
                                    <div className="pagination justify-content-center align-items-center pb-3">

                                        <button className="prev-btn"
                                            onClick={() => setPage((prev) => prev - 1)}
                                            disabled={page === 1 || isFetching}
                                        >
                                            {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-left"></i>}
                                        </button>
                                        <button className="next-btn"
                                            onClick={() => setPage((prev) => prev + 1)}
                                            disabled={page >= dataCustomDeal.totalPages || isFetching}
                                        >
                                            {isFetching ? <span className="spinner-border spinner-border-sm"></span> : <i className="fa-solid fa-chevron-right"></i>}
                                        </button>
                                        <div className="page px-1">
                                            {`${page} / ${dataCustomDeal.totalPages}`}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            <Modal show={showModel} >
                <Modal.Header>
                    <Modal.Title>
                        <h5 className="modal-title">{dealId === 0 ? "Create" : "Edit"} Custom Deal</h5>
                    </Modal.Title>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => setShowModel(false)} ></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="deal-form modal-body">
                        <div className="row ">
                            <div className="col-12 mt-3">
                                <label htmlFor="dealName">Deal Name</label>
                                <input type="text" id="dealName" name="dealName" value={dealName} onChange={(e) => setDealName(e.target.value)} className={dealName === "" ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"} />
                            </div>
                            <div className="col-12 mt-3 d-flex align-items-center gap-2">
                                <div className={previewImage === undefined ? "border border-danger" : ""}>
                                    <div className="px-4">
                                        <label>Upload Image (Max 20MB | Dimension 180 x 240)</label>
                                        <div className="row p-1">
                                            <div><input type="file" name="dealName" className="py-1" onChange={(e) => selectFile(e)} accept="image/x-png,image/gif,image/jpeg" /></div>
                                            {previewImage !== undefined ?
                                                <div className="d-flex align-items-center justify-content-center my-2" style={{ 'border': 'dotted 4px', 'borderRadius': '12px', 'padding': '4px' }} >
                                                    <img src={previewImage} alt="" style={{ maxHeight: '120px' }} />
                                                </div>
                                                :
                                                currentFile !== '' &&
                                                <div className="d-flex align-items-center justify-content-center my-2" style={{ 'border': 'dotted 4px', 'borderRadius': '12px', 'padding': '4px' }} >
                                                    <img src={process.env.REACT_APP_BASE_URL + `MediaStorage/DealImages/` + currentFile} alt="" style={{ maxHeight: '120px' }} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-6 mt-3">
                                <label htmlFor="startDate">Start Date</label>
                                <input type="date"
                                    onChange={(e) => setStartDate(e.target.value)}
                                    required
                                    value={startDate}
                                    className={startDate === "" ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"} />
                            </div>
                            <div className="col-6 mt-3">
                                <label htmlFor="endDate">End Date</label>
                                <input type="date"
                                    onChange={(e) => setEndDate(e.target.value)}
                                    required
                                    value={endDate}
                                    className={endDate === "" ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"} />
                            </div>
                            <div className="col-12 border border-success rounded my-3 py-2">
                                <div className="col-12">
                                    <input onChange={(e) => setIsExclusiveDeal(e.target.checked)} checked={isExclusiveDeal} type="checkbox" id="exclusiveDeal" className="form-check-input" />&nbsp;
                                    <label className="form-check-label pe-1" htmlFor="exclusiveDeal">Publish this in HalalCaters Exclusive deal page</label>
                                </div>
                                {isExclusiveDeal &&
                                    <div className="col-12 mt-3">
                                        <label htmlFor="tag">Select HalalCates Exclusive Deal category</label>
                                        {!isLoadingCategory &&
                                            <select className={categoryId === "0" ? "form-select border border-danger" : "form-select"}
                                                onChange={(e) => setCategoryId(e.target.value)} value={categoryId}>
                                                <option value="0" >Select</option>
                                                {
                                                    dataCategory.payload.map((st, index) =>
                                                        <option key={index} value={st.dealPromotionCategoryId} >{st.dealPromotionCategoryName}</option>
                                                    )
                                                }
                                            </select>
                                        }
                                    </div>
                                }
                            </div>
                            <div className="col-12 mt-3">
                                <label htmlFor="url">URL</label>
                                <input type="text"
                                    onChange={(e) => setUrl(e.target.value)}
                                    required
                                    value={url}
                                    maxLength="120" />
                            </div>

                            <div className="col-12 mt-3 d-flex align-items-center">
                                <label className=" form-check-label pe-1" htmlFor="isActive2">Active: </label>
                                <input onChange={(e) => setStatus(e.target.checked)} checked={status} type="checkbox" id="isActive2" className="form-check-input" />
                            </div>

                            <div className="col-12 mt-3">
                                <label className=" form-check-label pe-1" htmlFor="note">Notes </label>
                                <textarea id="note" value={note} onChange={(e) => setNote(e.target.value.slice(0, 1000))} rows="2"></textarea>
                            </div>

                            <div className="col-12 mt-3">
                                <button className="btn btn-danger" onClick={() => {
                                    saveCustomDeal();
                                    setShowModel(false);
                                }}
                                    disabled={(isExclusiveDeal && (categoryId === null || categoryId === '0')) || dealName === '' || startDate === "" || endDate === "" || (dealId === 0 && previewImage === undefined) || !valideUrl(url)}>Save</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>


    )
}




const Deal = () => {




    return (
        <div>
            <div className="row">
                <div className="signin-up-wrapper">
                    <h3>
                        <strong>Deal</strong>
                    </h3>
                </div>
            </div>

            <div className="row search-tab align-items-end py-3">
                <div className="col-12">

                    <ul className="nav nav-tabs" id="myDealTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="flat-deal-tab" data-bs-toggle="tab" data-bs-target="#flat-deal" type="button" role="tab" aria-controls="flat-deal" aria-selected="true" >Flat Deal</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="custom-deal-tab" data-bs-toggle="tab" data-bs-target="#custom-deal" type="button" role="tab" aria-controls="custom-deal" aria-selected="false" >Custom Deal</button>
                        </li>
                    </ul>
                    <div className="tab-content border" id="myTabContent">
                        <div className="tab-pane fade show active" id="flat-deal" role="tabpanel" aria-labelledby="flat-deal">
                            <FlatDeal />
                        </div>
                        <div className="tab-pane fade" id="custom-deal" role="tabpanel" aria-labelledby="custom-deal-tab">
                            <CustomDeal />
                        </div>
                    </div>

                </div>
            </div>





        </div>
    )
}

export default Deal
