import { toast } from 'react-toastify';
import { useAddReviewMutation } from '../hooks/apiSlice';
import { useParams } from "react-router-dom";
import moment from 'moment'
import React, { useState, useEffect } from 'react';
import AuthService from "../hooks/Auth";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { Chart, ArcElement } from 'chart.js'
import SignupPanel from './../components/SignupPanel';
import SigninPanel from './../components/SigninPanel';
import { StarRating } from "./../components/RatingStar";
import Modal from 'react-bootstrap/Modal';
import RatingStar from "./RatingStar"
Chart.register(ArcElement);




let page = 1;
const Review = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const params = useParams();

    const [rating, setRating] = useState(0);
    const [rating2, setRating2] = useState(0);
    const [rating3, setRating3] = useState(0);

    const [valRating, setValRating] = useState();
    const [valRating2, setValRating2] = useState();
    const [valRating3, setValRating3] = useState();



    const [comment, setComment] = useState('');

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);

    //const { data: dataReviewByCaterer, isLoading: isLoadingReviewByCaterer } = useGetReviewByCompanyQuery({ companyid: params.companyId })
    //const { data: dataGetReviewByByCatererAndUserProfile, isLoading: isLoadingGetReviewByByCatererAndUserProfile } = useGetReviewByCompanyAndUserProfileQuery({ companyId: params.companyId, userProfileId: AuthService.getUserProfileId() });
    const [addReviewMutation] = useAddReviewMutation()

    //const { data: dataReviewAverageByCompany, isLoading: isLoadingReviewAverageByCompany } = useGetReviewAverageByCompanyQuery(params.companyId)


    const validateReviewSubmit = async (a, b, c) => {
        var val = true;
        if (a === 0) {
            setValRating(false)
            val = false;
        }
        if (b === 0) {
            setValRating2(false)
            val = false;
        }
        if (c === 0) {
            setValRating3(false)
            val = false;
        }
        return val;
    }



    const submitReview = async () => {
        if (await validateReviewSubmit(rating, rating2, rating3)) {

            let formData = new FormData()
            const files = Array.from(selectedFiles);

            formData.append("file", files);

            files.forEach(item => {
                (item.size <= 5242880) && formData.append('pictures', item)
            })

            await addReviewMutation({ file: formData, 'company.companyId': params.companyId, 'userProfile.userProfileId': AuthService.getUserProfileId(), comment: comment, ratingTaste: rating, ratingOnTimeDelivery: rating2, ratingCommunication: rating3 }).unwrap();

            axios.get(process.env.REACT_APP_BASE_URL + `Review/GetReviewByCompanyId/${params.companyId}/${1}`)
                .then((response) => {
                    setData(response.data);
                })

            setSelectedFiles([]);
            setImagePreviews([]);
            setComment('');

            setRating(0);
            setRating2(0);
            setRating3(0);
        }
    }


    const handleChange = (value) => {
        setValRating(true);
        setRating(value);
    }

    const handleChange2 = (value) => {
        setValRating2(true);
        setRating2(value);
    }

    const handleChange3 = (value) => {
        setValRating3(true);
        setRating3(value);
    }



    const selectFiles = (event) => {
        let imageFileSizeExceed = false;
        if ((imagePreviews.length + event.target.files.length) > 5)
            toast.error(`Please do not upload more than 5 pictures. Thank You!`, { position: toast.POSITION.TOP_CENTER });
        else {
            for (let i = 0; i < event.target.files.length; i++) {
                if (event.target.files[i].size > 5242880) {
                    imageFileSizeExceed = true;
                }
                else {
                    setImagePreviews(imagePreviews => [...imagePreviews, URL.createObjectURL(event.target.files[i])]);
                    setSelectedFiles(selectedFiles => [...selectedFiles, event.target.files[i]]);
                }
            }

            if (imageFileSizeExceed)
                toast.warning(`One or more image file size exceeds from 5MB, that has been excluded.`, { position: toast.POSITION.TOP_CENTER })
        }
    };


    const removeImage = (event) => {
        const newPeople = imagePreviews.filter((_, i) => i !== parseInt(event.target.previousElementSibling.alt))
        const newPeople2 = selectedFiles.filter((_, i) => i !== parseInt(event.target.previousElementSibling.alt))

        setImagePreviews(newPeople);
        setSelectedFiles(newPeople2);
    };

    const [data, setData] = useState([]);
    const applyFilters = async () => {
        page = page + 1;
        await axios.get(process.env.REACT_APP_BASE_URL + `Review/GetReviewByCompanyId/${params.companyId}/${page}`)
            .then((response) => {
                setData([...data, ...response.data]);
            })
    };



    useEffect(() => {
        axios.get(process.env.REACT_APP_BASE_URL + `Review/GetReviewByCompanyId/${params.companyId}/${1}`)
            .then((response) => {
                setData(response.data);
            })
    }, []);


    const getAvgStars = (rating) => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            if (rating - 1 < i) {
                stars.push(<i key={i} className="fa-solid fa-star checked" style={{ 'color': '#ddd' }}></i>);
            } else {
                stars.push(<i key={i} className="fa-solid fa-star checked"></i>);
            }
        }
        return stars;
    }



    return (
        <>
            <Modal className="register-modal" show={show} onHide={handleClose} backdrop="static" keyboard="False">
                <Modal.Header closeButton>
                    <Modal.Title><h3 className="text-danger">Create your identity before submitting a Review</h3></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-12">
                        <div className="row margin0">
                            <div>
                                <h3 className="text-danger">Signin</h3>
                                <div className="col-lg-12 d-flex justify-content-center border rounded p-3 mt-4 loginwith">
                                    <div className="row margin0 pt-3 form-log">

                                        <SigninPanel parent="CompanyDetailPage" onSuccessSignup={() => { submitReview(); setShow(false) }} />

                                    </div>
                                </div>
                                <div className="col-lg-12 justify-content-center border rounded p-3 mt-4 loginwith">
                                    <h3 className="text-danger">Sign-up</h3>
                                    <SignupPanel userType={'customer'} parent="CompanyDetailPage" onSuccessSignup={() => { submitReview(); setShow(false) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>






            <section className="Review new-reviews" id="reviews">
                <div className="row customer-reviews-cols p-3 border-top">
                    <div className="col-12 col-md-12 col-md-6* col-lg-5* col-xl-6* ">
                        <div className="row mt-4 ms-xl-5* review-form">

                            <div className="col-12 align-self-start">
                                <h5 className="section-title">Reviews</h5>
                                <p>Customers rated this pro highly for <strong>Taste</strong>, <strong>On Time Delivery</strong> and <strong>Communication</strong>.</p>
                                <div className="row rating-info align-items-center">
                                    <div className="col-sm-6 col-lg-4 mb-3 mb-sm-0">
                                        <h6>{props.reviewData.payload.ratingGrade} {props.reviewData.payload.activeReviewCount !== 0 && props.reviewData.payload.ratingAverage + (props.reviewData.payload.ratingAverage.toString().length === 1 && ".0")}</h6>

                                        <div className="review-stars">
                                            {/* {getAvgStars(props.reviewData.payload.ratingAverage)} */}
                                            <RatingStar starCount={props.reviewData.payload.ratingAverage}/>


                                        </div>
                                        <p>{props.reviewData.payload.activeReviewCount} Reviews</p>
                                    </div>
                                    <div className="col-sm-6 ps-sm-0 d-block d-lg-none">

                                        <div className="mb-3">
                                            <div className="row hori-rating align-items-center">
                                                <div className="col-7 col-sm-6 col-md-7 col-lg-6">
                                                    <p>Taste</p>
                                                </div>
                                                <div className="col-5 col-sm-6 col-md-5 col-lg-6 vendor-detail-stars">
                                                    <span style={{ 'textDecoration': 'none', 'marginRight': '10px' }}>{props.reviewData.payload.ratingTaste}{props.reviewData.payload.ratingTaste.toString().length === 1 && ".0"}</span>
                                                    {Array.from({ length: props.reviewData.payload.ratingTaste }).map((s, index) => {
                                                        return <i key={index} className="fa-solid fa-star checked"></i>
                                                    })}
                                                </div>
                                            </div>
                                            <div className="row hori-rating align-items-center">
                                                <div className="col-7 col-sm-6 col-md-7 col-lg-6">
                                                    <p>On Time Delivery</p>
                                                </div>
                                                <div className="col-5 col-sm-6 col-md-5 col-lg-6 vendor-detail-stars">
                                                    <span style={{ 'textDecoration': 'none', 'marginRight': '10px' }}>{props.reviewData.payload.ratingOnTimeDelivery.toString()}{props.reviewData.payload.ratingOnTimeDelivery.toString().length === 1 && ".0"}</span>
                                                    {Array.from({ length: props.reviewData.payload.ratingOnTimeDelivery }).map((s, index) => {
                                                        return <i key={index} className="fa-solid fa-star checked"></i>
                                                    })}
                                                </div>
                                            </div>

                                            <div className="row hori-rating align-items-center">
                                                <div className="col-7 col-sm-6 col-md-7 col-lg-6">
                                                    <p>Communication</p>
                                                </div>
                                                <div className="col-5 col-sm-6 col-md-5 col-lg-6 vendor-detail-stars">
                                                    <span style={{ 'textDecoration': 'none', 'marginRight': '10px' }}>{props.reviewData.payload.ratingCommunication}{props.reviewData.payload.ratingCommunication.toString().length === 1 && ".0"}</span>
                                                    {Array.from({ length: props.reviewData.payload.ratingCommunication }).map((s, index) => {
                                                        return <i key={index} className="fa-solid fa-star checked"></i>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-lg-4">
                                        <div className="rating-number-info">
                                            <div className="info">
                                                <span>5</span>
                                                <svg width="10" height="10" viewBox="0 0 8 8"><path d="M4 0a.324.324 0 0 0-.31.247l-.787 2.536H.32c-.178 0-.32.162-.32.35 0 .114.055.217.138.277.052.037 2.087 1.588 2.087 1.588s-.782 2.505-.797 2.545a.39.39 0 0 0-.02.118c0 .188.145.339.324.339a.316.316 0 0 0 .185-.06L4 6.355 6.083 7.94c.053.038.117.061.185.061a.332.332 0 0 0 .324-.34.387.387 0 0 0-.02-.117c-.015-.04-.797-2.545-.797-2.545S7.81 3.447 7.862 3.41A.346.346 0 0 0 8 3.13c0-.185-.138-.347-.317-.347H5.1L4.31.247A.324.324 0 0 0 4 0" fillRule="evenodd"></path></svg>
                                                <div className="progress">
                                                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{ 'width': props.reviewData.payload.ratingPercentageFive.toString() + '%' }}></div>
                                                </div>
                                                <span style={{ 'width': '30px' }}>{props.reviewData.payload.ratingPercentageFive}%</span>
                                            </div>
                                            <div className="info">
                                                <span>4</span>
                                                <svg width="10" height="10" viewBox="0 0 8 8"><path d="M4 0a.324.324 0 0 0-.31.247l-.787 2.536H.32c-.178 0-.32.162-.32.35 0 .114.055.217.138.277.052.037 2.087 1.588 2.087 1.588s-.782 2.505-.797 2.545a.39.39 0 0 0-.02.118c0 .188.145.339.324.339a.316.316 0 0 0 .185-.06L4 6.355 6.083 7.94c.053.038.117.061.185.061a.332.332 0 0 0 .324-.34.387.387 0 0 0-.02-.117c-.015-.04-.797-2.545-.797-2.545S7.81 3.447 7.862 3.41A.346.346 0 0 0 8 3.13c0-.185-.138-.347-.317-.347H5.1L4.31.247A.324.324 0 0 0 4 0" fillRule="evenodd"></path></svg>
                                                <div className="progress">
                                                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{ 'width': props.reviewData.payload.ratingPercentageFour.toString() + '%' }}></div>
                                                </div>
                                                <span style={{ 'width': '30px' }}>{props.reviewData.payload.ratingPercentageFour}%</span>
                                            </div>
                                            <div className="info">
                                                <span>3</span>
                                                <svg width="10" height="10" viewBox="0 0 8 8"><path d="M4 0a.324.324 0 0 0-.31.247l-.787 2.536H.32c-.178 0-.32.162-.32.35 0 .114.055.217.138.277.052.037 2.087 1.588 2.087 1.588s-.782 2.505-.797 2.545a.39.39 0 0 0-.02.118c0 .188.145.339.324.339a.316.316 0 0 0 .185-.06L4 6.355 6.083 7.94c.053.038.117.061.185.061a.332.332 0 0 0 .324-.34.387.387 0 0 0-.02-.117c-.015-.04-.797-2.545-.797-2.545S7.81 3.447 7.862 3.41A.346.346 0 0 0 8 3.13c0-.185-.138-.347-.317-.347H5.1L4.31.247A.324.324 0 0 0 4 0" fillRule="evenodd"></path></svg>
                                                <div className="progress">
                                                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{ 'width': props.reviewData.payload.ratingPercentageThree.toString() + '%' }}></div>
                                                </div>
                                                <span style={{ 'width': '30px' }}>{props.reviewData.payload.ratingPercentageThree}%</span>
                                            </div>
                                            <div className="info">
                                                <span>2</span>
                                                <svg width="10" height="10" viewBox="0 0 8 8"><path d="M4 0a.324.324 0 0 0-.31.247l-.787 2.536H.32c-.178 0-.32.162-.32.35 0 .114.055.217.138.277.052.037 2.087 1.588 2.087 1.588s-.782 2.505-.797 2.545a.39.39 0 0 0-.02.118c0 .188.145.339.324.339a.316.316 0 0 0 .185-.06L4 6.355 6.083 7.94c.053.038.117.061.185.061a.332.332 0 0 0 .324-.34.387.387 0 0 0-.02-.117c-.015-.04-.797-2.545-.797-2.545S7.81 3.447 7.862 3.41A.346.346 0 0 0 8 3.13c0-.185-.138-.347-.317-.347H5.1L4.31.247A.324.324 0 0 0 4 0" fillRule="evenodd"></path></svg>
                                                <div className="progress">
                                                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{ 'width': props.reviewData.payload.ratingPercentageTwo.toString() + '%' }}></div>
                                                </div>
                                                <span style={{ 'width': '30px' }}>{props.reviewData.payload.ratingPercentageTwo}%</span>
                                            </div>
                                            <div className="info">
                                                <span>1</span>
                                                <svg width="10" height="10" viewBox="0 0 8 8"><path d="M4 0a.324.324 0 0 0-.31.247l-.787 2.536H.32c-.178 0-.32.162-.32.35 0 .114.055.217.138.277.052.037 2.087 1.588 2.087 1.588s-.782 2.505-.797 2.545a.39.39 0 0 0-.02.118c0 .188.145.339.324.339a.316.316 0 0 0 .185-.06L4 6.355 6.083 7.94c.053.038.117.061.185.061a.332.332 0 0 0 .324-.34.387.387 0 0 0-.02-.117c-.015-.04-.797-2.545-.797-2.545S7.81 3.447 7.862 3.41A.346.346 0 0 0 8 3.13c0-.185-.138-.347-.317-.347H5.1L4.31.247A.324.324 0 0 0 4 0" fillRule="evenodd"></path></svg>
                                                <div className="progress">
                                                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{ 'width': props.reviewData.payload.ratingPercentageOne.toString() + '%' }}></div>
                                                </div>
                                                <span style={{ 'width': '30px' }}>{props.reviewData.payload.ratingPercentageOne}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-12 mt-5">
                                <h5 className="margin0">Share your feedback</h5>
                                <div className="row rating-boxes px-0 py-4">
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center bg-light-">
                                        <div className={valRating === false ? "star-validation" : ""}>
                                            <StarRating
                                                count={5}
                                                value={rating}
                                                activeColor={'#F2AF29'}
                                                onChange={handleChange} />
                                            <span style={{ 'color': '#111111' }} className="fw-bold">TASTE</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center bg-light-">
                                        <div className={valRating2 === false ? "star-validation" : ""}>
                                            <StarRating
                                                count={5}
                                                value={rating2}
                                                activeColor={'#F2AF29'}
                                                onChange={handleChange2} />
                                            <span style={{ 'color': '#111111' }} className="fw-bold">ON TIME DELIVERY</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 text-center bg-light-">
                                        <div className={valRating3 === false ? "star-validation" : ""}>
                                            <StarRating
                                                count={5}
                                                value={rating3}
                                                activeColor={'#F2AF29'}
                                                onChange={handleChange3} />
                                            <span style={{ 'color': '#111111' }} className="fw-bold">COMMUNICATION</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <textarea className="form-control" placeholder="Comments" text={comment} value={comment} onChange={(e) => setComment(e.target.value)} name="comments" />
                                </div>

                            </div>
                            <div className="col-12">
                                <div className="review-image-box p-2 my-2">
                                    <div className="row">
                                        {imagePreviews && (
                                            imagePreviews.map((img, i) => {
                                                return (
                                                    <div key={i} className="col-auto position-relative">
                                                        <img className="review-image-upload" src={img} alt={i} />
                                                        <img className="review-image-remove" src="/images/reviewImageRemove.png" alt="remove" title="remove" value={i} onClick={(e) => removeImage(e)} data="s" ></img>
                                                    </div>
                                                );
                                            })
                                        )}

                                        {(imagePreviews.length < 5) &&
                                            <div className="col-auto">
                                                <input type="file" multiple accept="image/*" onChange={selectFiles} id="upload" />
                                                <label id="upload-label" htmlFor="upload" className="btn-picture-upload">
                                                    <i className="fas fa-upload"></i><br />Upload Picture
                                                </label>
                                            </div>
                                        }
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="review-picture-upload-note mt-2">Max. 5 pictures upto 5MB</div>
                                        <button className="btn btn-danger" onClick={(e) => AuthService.getCurrentUser() === null ? setShow(true) : submitReview()}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-xl-6* col-lg-7* col-md-12 col-sm-12 col-12 px-xl-5* position-relative mt-5 mt-md-0">

                        {data.length === 0 ?
                            <h4 className="mt-5">Be the first one to submit a review</h4>
                            :
                            <>
                                <div id="scrollableDiv" style={{ overflow: "auto", height: "400px" }}>
                                    <InfiniteScroll className="infinite-scroll"
                                        dataLength={data.length}
                                        next={() => { applyFilters(setData, data) }}
                                        hasMore={true}
                                        scrollableTarget="scrollableDiv">
                                        {data.map((review, index) => (
                                            <div key={index} className="review-items">
                                                <div className="row box py-4*">
                                                    <div className="col-12 user-info">

                                                        {review.reviewerAvatar === null ?
                                                            <div className="picture-placeholder">
                                                                <div className="reviewAvatar">{review.reviewerName.substring(0, 1)}</div>
                                                            </div>
                                                            :
                                                            <div className="user-picture">
                                                                <img style={{ 'borderRadius': '50%' }} src={process.env.REACT_APP_MEDIA_STORAGE + "Avatar/" + review.reviewerAvatar} alt="" />
                                                            </div>}

                                                        <div className="user-meta">
                                                            <div className="meta">
                                                                <p className="name">{review.reviewerName}</p> <p className="time">{moment.utc(new Date(review.reviewDate), 'DD/MM/YYYY hh:mm').local().startOf('seconds').fromNow()}</p>
                                                            </div>
                                                            <div className="stars-yellow me-2*">
                                                                {/* {
                                                                    Array.from({ length: review.ratingAverage }).map((s, index) => {
                                                                        return <i key={index} className="fa-solid fa-star checked"></i>
                                                                    })
                                                                } */}
                                                                <RatingStar starCount={review.ratingAverage}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 px-3* mt-2 user-review">
                                                        <p>
                                                            {review.comment}
                                                        </p>
                                                    </div>
                                                    <div className="col-12 px-3 pt-2">
                                                        <div className="row">
                                                            {review.reviewImages.map((reviewImg, index) =>
                                                                <div key={index} data-bs-toggle="modal" data-bs-target="#reviewImagesModal" className="col-auto px-1">
                                                                    <img className="review-image zoom*" src={process.env.REACT_APP_BASE_URL + "MediaStorage/ReviewImages/" + reviewImg.imageFileName + reviewImg.imageFileExtention} alt='' />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="review-image-modal modal fade" id="reviewImagesModal" tabIndex="-1" role="dialog" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content p-4">
                                                                    <div className="modal-header border-0 pt-2 pb-0">
                                                                        <h5 className="modal-title"></h5>
                                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div className="modal-body padding0">
                                                                        <div id="robotcarousel" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-touch="true">
                                                                            <div className="carousel-inner">
                                                                                {review.reviewImages.map((reviewImg, index) =>
                                                                                    <div key={index} className={index === 0 ? "carousel-item active" : "carousel-item"}>
                                                                                        <img className="d-block w-100* eventGalleryPicture" src={process.env.REACT_APP_BASE_URL + "MediaStorage/ReviewImages/" + reviewImg.imageFileName + reviewImg.imageFileExtention} alt='' onClick={() => {
                                                                                            setImagePreviews(reviewImg.newPeople)
                                                                                        }} />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                            <button className="carousel-control-prev" type="button" data-bs-target="#robotcarousel" data-bs-slide="prev">
                                                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                                <span className="visually-hidden">Previous</span>
                                                                            </button>
                                                                            <button className="carousel-control-next" type="button" data-bs-target="#robotcarousel" data-bs-slide="next">
                                                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                                <span className="visually-hidden">Next</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="modal-footer border-0">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </InfiniteScroll>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </section >
        </>
    )

}

export default Review