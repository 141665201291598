import { toast } from 'react-toastify';
import AuthService from "./../../hooks/Auth";
import React, { useState, useEffect } from "react";
import DeleteConfirmation from '../../hooks/Confirmation';
import {
    useGetEventGalleryQuery
    , useGetEventGalleryPictureQuery
    , useSaveEventGalleryMutation, useDeleteEventGalleryMutation, useDeleteEventGalleryPictureMutation,
    useSaveEventGalleryNameMutation, useMarkCoverPictureMutation, useGetPackageFeatureQuery
} from './../../hooks/apiSlice';

const EventGallery = () => {


    const [selectedFiles, setSelectedFiles] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);

    const [eventGalleryId, setEventGalleryId] = useState(0);
    const [eventGalleryName, setEventGalleryName] = useState('');
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [selectedCoverPicture, setSelectedCoverPicture] = useState(false);

    const { data: dataEventGallery, isLoading: isLoadingEventGallery } = useGetEventGalleryQuery(0)
    const { data: dataEventGalleryPicture, isLoading: isLoadingEventGalleryPicture } = useGetEventGalleryPictureQuery(eventGalleryId)
    const { data: dataPackageFeature, isLoading: isLoadingPackageFeature } = useGetPackageFeatureQuery(2)

    const [saveEventGalleryMutation] = useSaveEventGalleryMutation()
    const [deleteEventGalleryMutation] = useDeleteEventGalleryMutation()
    const [deleteEventGalleryPictureMutation] = useDeleteEventGalleryPictureMutation()
    const [saveEventGalleryNameMutation] = useSaveEventGalleryNameMutation()
    const [markCoverPictureMutation] = useMarkCoverPictureMutation()

    const [deleteEventGalleryPictureId, setDeleteEventGalleryPictureId] = useState(0);
    const [deleteEventGalleryId, setDeleteEventGalleryId] = useState(0);

    let images = [];
    let images2 = [];
    const selectFiles = (event) => {
        if (event.target.files.length > 10) {
            toast.warning(`Please select maximum 10 pictures at a time.`, { position: toast.POSITION.TOP_CENTER })
        }
        else {
            let imageFileSizeExceed = false;
            for (let i = 0; i < event.target.files.length; i++) {
                if (event.target.files[i].size > 1048576) {
                    imageFileSizeExceed = true;
                }
                else {
                    images.push(URL.createObjectURL(event.target.files[i]));
                    images2.push(event.target.files[i]);
                }
            }
            setImagePreviews([...imagePreviews, ...images]);
            setSelectedFiles([...selectedFiles, ...images2]);

            if (imageFileSizeExceed)
                toast.warning(`One or more image file size exceeds from 1MB, that has been excluded.`, { position: toast.POSITION.TOP_CENTER })
        }
    };



    const removePreviewImage = (e) => {
        setImagePreviews(oldValues => {
            return oldValues.filter((_, i) => i !== e)
        })
        setSelectedFiles(oldValues => {
            return oldValues.filter((_, i) => i !== e)
        })
    }

    const uploadImages = async () => {
        let formData = new FormData()
        const files = Array.from(selectedFiles);

        formData.append("file", files);

        files.forEach(item => {
            formData.append('pictures', item)
        })

        const returned = await saveEventGalleryMutation({ file: formData, 'caterer.catererId': AuthService.getCatererId(), galleryName: eventGalleryName, eventGalleryId: eventGalleryId, 'caterer.userProfile.userProfileId': AuthService.getUserProfileId() });
        setEventGalleryId(returned.data);
        setImagePreviews([]);
        setSelectedFiles([]);
        document.getElementById('butModelClose').click();

        eventGalleryId !== 0 && updateEventGalleryName(eventGalleryName);
    };

    const updateEventGalleryName = (e) => {
        if (eventGalleryId !== '' && eventGalleryName !== '')
            saveEventGalleryNameMutation({ galleryName: eventGalleryName, eventGalleryId: eventGalleryId });
    }




    return (
        <div>
            <div className="row">
                <div className="signin-up-wrapper">
                    <h3>
                        <strong><img src="/images/eventGalleryIcon.svg" alt="" width="30" />&nbsp;Gallery</strong>
                    </h3>
                    <h6>
                        Tell your customers about your events
                    </h6>
                </div>

                <div>
                    <div className="row mt-4">
                        <div className="event-collection">
                            <div className="collection-box">
                                {(isLoadingPackageFeature === false && isLoadingEventGallery === false) &&
                                    (dataEventGallery.payload.length >= dataPackageFeature.payload.packageFeatureValue) ?
                                    <div>Your current subscription does not allow you to add more Galleries <a href='../company-panel'>Click here to upgrade your subscription</a></div>
                                    :
                                    <button className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#eventModal" onClick={() => {
                                        setImagePreviews([]);
                                        setEventGalleryId(0);
                                        setEventGalleryName('');
                                    }}><i className="fa fa-plus-circle" aria-hidden="true" ></i> Add Gallery</button>
                                }
                            </div>

                            <div className="row">
                                {isLoadingEventGallery ? "" :
                                    dataEventGallery.payload.map((eg, index) =>
                                        eg.eventGalleryPictures.length !== 0 &&
                                        <div key={index} className="col-12 col-sm-6 col-lg-4 mt-4 position-relative">
                                            <div className="albums">
                                                <img className="album-img" src={process.env.REACT_APP_MEDIA_STORAGE + "EventGalleryImages/" + eg.eventGalleryPictures[0].pictureFileName} data-bs-toggle="modal" data-bs-target="#eventModal" onClick={(e) => {
                                                    setEventGalleryId(eg.eventGalleryId)
                                                    setEventGalleryName(eg.galleryName)
                                                    setSelectedCoverPicture(eg.eventGalleryPictures[0].eventGalleryPictureId)
                                                }} alt="" />
                                                <span className="photo-count">{eg.pictureCount} Photos</span>
                                                <div className="album-title">
                                                    <p>{eg.galleryName}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>

                            <div className="event-collection-modal modal fade" id="eventModal" tabIndex="-1" role="dialog" aria-hidden="true">
                                <div className="modal-dialog modal-xl" role="document">
                                    <div className="modal-content p-4">

                                        <div className="modal-header p-4 pb-0">
                                            <div className="modal-title col-lg-7 col-md-12 col-12 padding0">
                                                <label>Gallery Title</label>
                                                <input style={{ 'cursor': 'pointer' }} onChange={(e) => setEventGalleryName(e.target.value)} value={eventGalleryName} maxLength="160" className={eventGalleryName === "" ? "form-control form-control-lg border border-danger" : "form-control form-control-lg"} type="text" placeholder="Gallery Title" aria-label="Eventname" />
                                                {eventGalleryName === "" && <div className="text-danger">Enter Gallery Name</div>}
                                            </div>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="butModelClose"></button>
                                        </div>

                                        {/* <div className="modal-header p-0 pb-4">
                                            <div className="modal-title col-lg-7 col-md-7 col-10 ps-lg-0 p-lg-2 px-md-2">
                                                <input style={{'cursor':'pointer'}} onChange={(e) => setEventGalleryName(e.target.value)} value={eventGalleryName} maxLength="160" className="borderOnClick form-control form-control-lg" type="text" placeholder="Gallery Title" aria-label="Eventname" onBlur={(e) => { eventGalleryId !== 0 && updateEventGalleryName(e) }} />
                                            </div>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div> */}
                                        <div className="modal-body p-4">
                                            <div className="row border-bottom mb-4 pb-4">
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <div className="row event-gallery-upload-items">
                                                        <div className="col-6 col-sm-4 col-md-4 col-lg-3 mt-4 input-group*">
                                                            <div className="upload-btn">
                                                                <input type="file" multiple accept="image/x-png,image/gif,image/jpeg" onChange={selectFiles} id="upload" className="form-control border-0" />
                                                                <label id="upload-label" htmlFor="upload" className="font-weight-light text-muted"><i className="fa fa-plus-circle" aria-hidden="true"></i><small className="font-weight-bold text-muted">Upload Photos</small><span style={{ 'fontSize': '0.7rem' }}>recommended size: 700 x 700</span></label>
                                                            </div>
                                                        </div>
                                                        {imagePreviews && (
                                                            imagePreviews.map((img, i) => {
                                                                return (
                                                                    <div key={i} className="col-6 col-sm-6 col-md-6 col-lg-3 mt-4 position-relative text-center">
                                                                        <div className="event-gallery-photo ">
                                                                            <img src={img} alt={"image-" + i} />
                                                                            <div className="event-item-controls">
                                                                                <div className="form-check">
                                                                                    <input type="radio" className="form-check-input" id="cover" name="cover" />
                                                                                    <label className="form-check-label" htmlFor="cover" >Make Cover</label>
                                                                                </div>
                                                                                <button><i className="fa fa-trash" aria-hidden="true" onClick={(e) => {
                                                                                    removePreviewImage(i)
                                                                                }}></i></button>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                        {/* {imagePreviews && (
                                                            imagePreviews.map((img, i) => {
                                                                return (
                                                                    <div key={i} className="col-6 col-sm-4 col-md-4 col-lg-3 mt-4 position-relative text-center">
                                                                        <div className="event-gallery-photo ">
                                                                            <img src={img} alt={"image-" + i} />
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        )} */}

                                                    </div>

                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12 mt-4">
                                                    <button onClick={uploadImages} disabled={!selectedFiles || eventGalleryName === ''} className="btn btn-danger float-end">Save Photos</button>
                                                </div>
                                            </div>

                                            <div className="row event-gallery-items">

                                                {isLoadingEventGalleryPicture ? "" :

                                                    dataEventGalleryPicture.payload.map((egp, index) =>

                                                        <div key={index} className="col-6 col-sm-6 col-md-6 col-lg-3 mt-4 position-relative text-center" id={'eventgallerypicture_' + egp.eventGalleryPictureId}>
                                                            <div className="event-gallery-photo ">
                                                                <img src={process.env.REACT_APP_BASE_URL + "MediaStorage/EventGalleryImages/" + egp.pictureFileName} />
                                                                <div className="event-item-controls">
                                                                    <div className="form-check">
                                                                        <input type="radio" checked={selectedCoverPicture.toString() === egp.eventGalleryPictureId.toString()} onChange={(e) => {
                                                                            markCoverPictureMutation(egp.eventGalleryPictureId)
                                                                            setSelectedCoverPicture(e.target.value)
                                                                        }} className="form-check-input" id={egp.eventGalleryPictureId} name="cover" value={egp.eventGalleryPictureId} />
                                                                        <label className="form-check-label" htmlFor={egp.eventGalleryPictureId} >Make Cover</label>
                                                                    </div>
                                                                    <button><i className="fa fa-trash" onClick={(e) => {
                                                                        setDisplayConfirmationModal(true)
                                                                        setDeleteEventGalleryPictureId(egp.eventGalleryPictureId)
                                                                    }} aria-hidden="true"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>

                                        <div className="modal-footer">
                                            <div className="row pt-2 pb-4 event-info margin0 justify-content-end">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={() => {
                                    deleteEventGalleryPictureMutation(deleteEventGalleryPictureId)
                                    setDisplayConfirmationModal(false);
                                    //document.getElementById('eventgallerypicture_' + deleteEventGalleryPictureId).remove();
                                }} hideModal={() => { setDisplayConfirmationModal(false) }} message={"Are you sure to delete this Picture ?"} />
                            </div>



                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventGallery