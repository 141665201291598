import React, { useState } from "react";
import { useGetAllPromotionCategoryQuery, useSaveDealPromotionCategoryMutation, useDeleteDealPromotionCategoryMutation, useGetDealByDealPromotionCategoryIdQuery } from '../hooks/apiSlice';
import Modal from 'react-bootstrap/Modal';
import { isNumberKey } from '../hooks/util';
import moment from 'moment'

const DealPromotion = () => {

    const [active, setActive] = useState(true);
    const [inactive, setInActive] = useState(true);

    const [sortField, setSortField] = useState("DealPromotionCategoryName");
    const [order, setOrder] = useState("asc");

    const [showDealModel, setShowDealModel] = useState(false);
    const [showCreateModel, setShowCreateModel] = useState(false);
    const [showModifyModel, setShowModifyModel] = useState(false);

    const { data: dataDealPromotion, isLoading: isLoadingSubscription, isFetching } = useGetAllPromotionCategoryQuery({ includeActive: active, includeInActive: inactive, sortColumn: sortField, sortDirection: order })



    const handleSortingChange = (accessor) => {
        setSortField(accessor);
        setOrder(order === "asc" ? "desc" : "asc");
    };


    function getColumnHeader(columnName, sortString) {
        const arrowDirection = sortField === sortString && order === "asc" ? <i className="fas fa-arrow-up"></i> : sortField === sortString && order === "desc" ? <i className="fas fa-arrow-down"></i> : ""

        return (
            <span onClick={() => handleSortingChange(sortString)}> {isFetching ? <span className="ms-2 spinner-border spinner-border-sm"></span> : columnName} {arrowDirection}</span>
        )
    }

    const [saveDealPromotionCategory] = useSaveDealPromotionCategoryMutation()
    const [deleteDealPromotionCategory] = useDeleteDealPromotionCategoryMutation()

    function CreateDealPromotionCategory() {

        const [createCategoryName, setCreateCategoryName] = useState('');

        const handleSubmit = () => {
            if (createCategoryName !== '')
                saveDealPromotionCategory({ dealPromotionCategoryName: createCategoryName, dealPromotionCategoryStatus: 'Active' });
            setShowCreateModel(false);
        }

        return (
            <Modal show={showCreateModel} >
                <Modal.Header>
                    <Modal.Title><i className="fas fa-bolt"></i>&nbsp;Create Deal Promotion Category</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row">
                        <div className="col-12">
                            <input type="text" name='dealPromotionCategoryName2' className="form-control" value={createCategoryName} onChange={(e) => setCreateCategoryName(e.target.value)}
                                maxLength="300" />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="adminButton" onClick={() => { handleSubmit() }}>
                        Save
                    </button>
                    <button className="adminButton" onClick={() => { setShowCreateModel(false) }}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    const [dealPromotion, setDealPromotion] = useState({ dealPromotionCategoryName: '', dealPromotionCategoryStatus: 0 });



    function ModifyDealPromotionCategory() {

        const [modifyActive, setModifyActive] = useState(dealPromotion.dealPromotionCategoryStatus);
        const [modifyCategoryName, setModifyCategoryName] = useState(dealPromotion.dealPromotionCategoryName);

        const handleSubmit = () => {
            saveDealPromotionCategory({ 'dealPromotionCategoryId': dealPromotion.dealPromotionCategoryId, dealPromotionCategoryName: modifyCategoryName, dealPromotionCategoryStatus: modifyActive ? 'Active' : 'InActive' });
            setShowModifyModel(false);
        }

        const removeDealPromotion = (targetAudienceId) => {
            deleteDealPromotionCategory({ 'dealPromotionCategoryId': dealPromotion.dealPromotionCategoryId });
            setShowModifyModel(false);
        }



        return (
            <Modal show={showModifyModel} >
                <Modal.Header>
                    <Modal.Title>MODIFY</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <input type="text" name='dealPromotionCategoryName' className="form-control" value={modifyCategoryName} onChange={(e) => setModifyCategoryName(e.target.value)}
                                maxLength="300" />
                        </div>
                        <div className="col-12 mt-2">
                            <div className="d-flex justify-content-end">
                                <input type="checkbox" className="form-check-input" style={{ width: '1.2em', height: '1.2em' }} id="active1" checked={modifyActive} onChange={(e) => setModifyActive(e.target.checked)}></input>
                                <label className="form-check-label ps-1" htmlFor="active1">Active</label>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="adminDeleteButton" onClick={() => { removeDealPromotion(22) }}>Remove</button>
                    <button className="adminButton" onClick={() => { handleSubmit() }}>
                        Save
                    </button>
                    <button className="adminButton" onClick={() => { setShowModifyModel(false) }}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }


    const [dealPromotionCategoryId, setDealPromotionCategoryId] = useState(0);
    const [dealPromotionCategoryName, setDealPromotionCategoryName] = useState('');

    function DealByDealPromotionCategory() {

        const { data: dataDeal, isLoading: isLoadingDeal, isFetching } = useGetDealByDealPromotionCategoryIdQuery(dealPromotionCategoryId)

        return (
            <Modal size="xl" show={showDealModel} >
                <Modal.Header>
                    <Modal.Title><i className="fas fa-bolt"></i>&nbsp;{dealPromotionCategoryName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoadingDeal ? "Loading..." :
                        <table className="table-hover table-striped table">
                            <thead>
                                <tr>
                                    <th text-center>Company Name</th>
                                    <th text-center>Detail</th>
                                    <th text-center>Status</th>
                                    <th text-center>Period</th>
                                    <th text-center>Created On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataDeal.payload.map((bst, index) =>
                                    <tr key={index}>
                                        <td>
                                            <div className="row"><div className="col-auto"><img src={bst.logoFileName ? process.env.REACT_APP_MEDIA_STORAGE + "logo/" + bst.logoFileName : '/images/DefaultCaterer.png'} alt="" style={{ 'height': '30px', 'width': '30px' }} className="bg-info rounded-circle" /></div><div className="col p-0"><a href={'../company-detail/' + bst.companyId + '/' + (bst.locationSlug === '' ? 'none' : bst.locationSlug) + '/' + bst.companySlug} target="_blank" style={{ 'textDecoration': 'none' }}>{bst.companyName}</a><br /><span style={{ 'fontSize': '11px' }}>{bst.companySlug}</span></div>
                                            </div>
                                        </td>
                                        <td className="align-middle text-center">
                                            {bst.dealType === "Custom Deal" ?
                                                <span className="align-middle">
                                                    <b>{bst.dealName}</b><br />
                                                    <img className="item-img border-0" src={process.env.REACT_APP_MEDIA_STORAGE + "DealImages/" + (bst.imageFileName)} alt={bst.imageFileName} width={80} />
                                                </span>
                                                :
                                                <span className="align-middle" style={{'fontSize':'20px'}}><b>{bst.percentageOff}%</b></span>
                                            }
                                        </td>
                                        <td className="align-middle text-center">{bst.status ? <i className="fa-solid fa-circle-check text-success"></i> : <i className="fa-sharp fa-solid fa-circle-xmark text-danger"></i>}</td>
                                        <td>{bst.period}</td>
                                        <td>{moment(bst.createdOn).format("MMMM-DD-YYYY")}</td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className="adminButton" onClick={() => { setShowDealModel(false) }}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }


    return (
        <>
            {isLoadingSubscription ? "" :
                <div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-10">
                                <h4><b><i className="fas fa-bolt"></i>&nbsp;Deal Promotion Categories</b></h4>
                            </div>
                            <div className="col-md-2 text-end">
                                <button style={{ 'fontSize': '0.7rem', 'fontWeight': '600' }} className="pull-right adminButton" onClick={() => {
                                    setShowCreateModel(true);
                                }}><i className="fas fa-plus"></i>&nbsp;Create</button>
                            </div>
                            <CreateDealPromotionCategory />
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="strpied-tabled-with-hover card">
                                    <div className="card-header"><div className="row">
                                        <div className="col-md-12">
                                            <div className="row justify-content-between">
                                                <div className="col-8">
                                                    &nbsp;
                                                </div>
                                                <div className="col-4">
                                                    <div className="d-flex justify-content-end">
                                                        <input type="checkbox" className="form-check-input" style={{ width: '1.2em', height: '1.2em' }} id="active" checked={active} onChange={(e) => setActive(e.target.checked)}></input>
                                                        <label className="form-check-label ps-1" htmlFor="active">Active</label>
                                                        &nbsp;&nbsp;&nbsp;
                                                        <input type="checkbox" className="form-check-input" style={{ width: '1.2em', height: '1.2em' }} id="inactive" checked={inactive} onChange={(e) => setInActive(e.target.checked)}></input>
                                                        <label className="form-check-label ps-1" htmlFor="inactive">Inactive</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>

                                        </div>
                                    </div>
                                    </div>
                                    <div className="table-full-width table-responsive px-0 card-body">

                                        <table className="table-hover table-striped table">
                                            <thead>
                                                <tr>
                                                    <th>{getColumnHeader('Promotion Category Name', 'DealPromotionCategoryName')}</th>
                                                    <th>{getColumnHeader('No Of Promotions', 'DealPromotionCategoryName')}</th>
                                                    <th>{getColumnHeader('Status', 'DealPromotionCategoryStatus')}</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {dataDealPromotion.payload.map((bst, index) =>
                                                    <tr key={index}>
                                                        <td>{bst.dealPromotionCategoryName}</td>
                                                        <td className="text-center">{bst.noOfPromotion === 0 ? 0 : <div style={{ 'cursor': 'pointer', 'fontSize': '16px' }} className="badge rounded-pill bg-dark p-1 px-3" onClick={(e) => {
                                                            setDealPromotionCategoryId(bst.dealPromotionCategoryId)
                                                            setDealPromotionCategoryName(bst.dealPromotionCategoryName)
                                                            setShowDealModel(true)
                                                        }}><i className="fa-solid fa-bell-concierge"></i>&nbsp;{bst.noOfPromotion}</div>}</td>
                                                        <td className="text-center">{bst.dealPromotionCategoryStatus ? <i className="fa-solid fa-circle-check text-success"></i> : <i className="fa-sharp fa-solid fa-circle-xmark text-danger"></i>}</td>
                                                        <td className="text-center">
                                                            <button className="pull-right adminButton" onClick={() => {
                                                                setDealPromotion({ dealPromotionCategoryId: bst.dealPromotionCategoryId, dealPromotionCategoryName: bst.dealPromotionCategoryName, dealPromotionCategoryStatus: bst.dealPromotionCategoryStatus });
                                                                setShowModifyModel(true)
                                                            }}>modify</button>
                                                        </td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                        <ModifyDealPromotionCategory />
                                        <DealByDealPromotionCategory />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }



        </>
    )
}





export default DealPromotion