


const earthRadius = 6378137;
const toRadius = (value) => {
    return (value * Math.PI) / 180;
}

export const CalculateDistance = ({ from, to }) => {
    const distance2 =
        Math.acos(
            Math.sin(toRadius(to.latitude)) * Math.sin(toRadius(from.latitude)) +
            Math.cos(toRadius(to.latitude)) * Math.cos(toRadius(from.latitude)) * Math.cos(toRadius(from.longitude) - toRadius(to.longitude)),
        ) * earthRadius;

    return Math.round(convertDistance(distance2, 'km'));
};

const distanceConversion = {
    m: 1,
    km: 0.001,
    cm: 100,
    mm: 1000,
    mi: 0.000621371,
    yd: 1.09361,
    ft: 3.28084,
};

const convertDistance = (meters, targetUnit = 'm') => {
    return distanceConversion[targetUnit] * meters;
};



export function getCoordinatesFromLink(url) {
    if (url === null)
    {
        return null;
    }
    else {
        const match = url.match(/@(-?\d+\.\d+),(-?\d+\.\d+)/);
        if (match) {
            return {
                latitude: parseFloat(match[1]),
                longitude: parseFloat(match[2])
            };
        }
        return null; // In case no coordinates are found  
    }
}

// Example usage  
const url = "https://www.google.com/maps/place/Mazar-e-Quaid-e-Azam/@24.8753916,67.0383972,17z/data=!3m1!4b1!4m6!3m5!1s0x3eb33e5e736977c1:0x70ed36c8a6891d3a!8m2!3d24.8753868!4d67.0409721!16zL20vMGI1azUz?entry=ttu&g_ep=EgoyMDI0MTExMi4wIKXMDSoASAFQAw%3D%3D";
const coordinates = getCoordinatesFromLink(url);



