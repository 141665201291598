import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { useGetEventGalleryQuery, useGetEventGalleryPictureQuery } from '../hooks/apiSlice';
import Carousel from 'react-elastic-carousel';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';



const EventGallery = (props) => {
    const sum = 0;
    const params = useParams();

    const [eventGalleryId, setEventGalleryId] = useState();
    const [eventGalleryName, setEventGalleryName] = useState();

    const { data: dataEventGallery, isLoading: isLoadingEventGallery } = useGetEventGalleryQuery(params.companyId)
    const { data: dataEventGalleryPicture, isLoading: isLoadingEventGalleryPicture } = useGetEventGalleryPictureQuery(eventGalleryId)
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2, itemsToScroll: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 4 }
    ];

    useEffect(() => {
        if (!isLoadingEventGallery) {
            if (dataEventGallery.payload.length > 0)
                props.showGalleryLink(true)
        }
    }, [isLoadingEventGallery])

    return (



        <div className="new gallery-section" id="event">












            {isLoadingEventGallery ? "" :
                dataEventGallery.payload.length > 0 &&

                <div className="row border-top gallery p-3">
                    <div className="col-12">
                        <h5 className="mb-4* margin0">Photo Gallery</h5>
                        <p>{dataEventGallery.payload.map((order) => order.pictureCount).reduce((prev, curr) => prev + curr)} Photos</p>
                        <section>
                            {
                                isLoadingEventGallery ? "" :

                                    <Carousel breakPoints={breakPoints} autoPlaySpeed={2000} >
                                        {dataEventGallery.payload.map((eg, index) =>
                                            eg.eventGalleryPictures.length !== 0 &&
                                            <div className="eventGalleryBoxView" key={index} data-bs-toggle="modal" data-bs-target="#eventGalleryModal" onClick={() => {
                                                setEventGalleryId(eg.eventGalleryId)
                                                setEventGalleryName(eg.galleryName)
                                            }}>
                                                <div className="image-wrap">
                                                    <div>
                                                        <img src={process.env.REACT_APP_MEDIA_STORAGE + "EventGalleryImages/" + eg.eventGalleryPictures[0].pictureFileName} width="180" height="180" alt="" />
                                                        <p className="eventGalleryName">{eg.galleryName}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Carousel>
                            }

                            <div className="vendor-detail-event-collection-modal modal fade" id="eventGalleryModal" tabIndex="-1" role="dialog" aria-hidden="true">
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content p-4 px-lg-5">
                                        <div className="modal-header border-0 pt-2 pb-0 padding0">
                                            <h5 className="modal-title">{eventGalleryName}</h5>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body">


                                            <Swiper
                                                slidesPerView={1}
                                                spaceBetween={30}
                                                keyboard={{
                                                    enabled: true,
                                                }}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                                navigation={true}
                                                modules={[Keyboard, Pagination, Navigation]}
                                                className="mySwiper"
                                            >



                                                {
                                                    isLoadingEventGalleryPicture ? "" :
                                                        dataEventGalleryPicture.payload.map((eg, index) =>


                                                            <SwiperSlide key={index}><img className="d-block w-100* eventGalleryPicture" src={process.env.REACT_APP_BASE_URL + "MediaStorage/EventGalleryImages/" + eg.pictureFileName} alt="Gallery-1" /></SwiperSlide>

                                                        )
                                                }


                                            </Swiper>




                                            {/* <div id="robotcarousel" className="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-touch="true">
                                                <div className="carousel-inner">
                                                    {
                                                        isLoadingEventGalleryPicture ? "" :
                                                            dataEventGalleryPicture.payload.map((eg, index) =>

                                                                <div key={index} className={index === 0 ? "carousel-item active" : "carousel-item"}>
                                                                    <img className="d-block w-100* eventGalleryPicture" src={process.env.REACT_APP_BASE_URL + "MediaStorage/EventGalleryImages/" + eg.pictureFileName} alt="Gallery-1" />
                                                                </div>
                                                            )
                                                    }
                                                </div>

                                                <button className="carousel-control-prev" type="button" data-bs-target="#robotcarousel" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target="#robotcarousel" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>

                                            </div> */}

                                        </div>

                                        <div className="modal-footer border-0">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            }
        </div>
    )
}


export default EventGallery